import React from "react";

const SummaryOfProgressiveLaboratoriesPl = () => {
	return (
		<>
			<div className="summary-of-benefits-main-container">
				<div className="summary-of-benefits-header">Summary of Benefits for Medical Plan</div>
				<div className="summary-of-benefits-body">
					<div className="summary-of-benefits-sub-header">
						<div className="sub-header-in-network">In Network</div>
						<div className="sub-header-out-of-network">Out of Network</div>
					</div>
					<div className="body-container-top">
						<div className="column-container">
							<div className="first-column">Deductible - Ind/Fam</div>
							<div className="second-column">$3,000/$6,000</div>
							<div className="third-column">$6,000/$12,000</div>
						</div>
						<div className="column-container">
							<div className="first-column">Out-of-Pocket - Ind/Fam</div>
							<div className="second-column">$4,000/$8,000</div>
							<div className="third-column">$8,000/$16,000</div>
						</div>
						<div className="column-container">
							<div className="first-column">Preventive care services</div>
							<div className="second-column">100%</div>
							<div className="third-column">100%</div>
						</div>
						<div className="column-container">
							<div className="first-column">Office Visit</div>
							<div className="second-column">$20 Copay</div>
							<div className="third-column">50% after deductible</div>
						</div>
						<div className="column-container">
							<div className="first-column">Annual routine physical examination</div>
							<div className="second-column">100%</div>
							<div className="third-column">100%</div>
						</div>
					</div>
					<div className="body-container-bottom">
						<div className="column-container">
							<div className="first-column">Hospital Services</div>
							<div className="second-column">90% after deductible</div>
							<div className="third-column">50% after deductible</div>
						</div>
						<div className="column-container">
							<div className="first-column">Labrotory Sevice</div>
							<div className="second-column">90% after deductible</div>
							<div className="third-column">50% after deductible</div>
						</div>
						<div className="column-container">
							<div className="first-column">Specialist consultation</div>
							<div className="second-column">$60 Copay</div>
							<div className="third-column">50% after deductible</div>
						</div>
						<div className="column-container">
							<div className="first-column">Emergency room facility ( Copay waived if admitted )</div>
							<div className="second-column">$75 Copay + 90% after deductible</div>
							<div className="third-column">$75 Copay + 90% after deductible</div>
						</div>
						<div className="column-container">
							<div className="first-column">Outpatients Service</div>
							<div className="second-column">90% after deductible</div>
							<div className="third-column">50% after deductible</div>
						</div>
						<div className="column-container">
							<div className="first-column">Others Service</div>
							<div className="second-column">90% after deductible</div>
							<div className="third-column">50% after deductible</div>
						</div>
					</div>
				</div>
			</div>
			<div className="summary-of-benefits-body-mobile">
				<div className="summary-of-benefits-header">Summary of Benefits for Medical Plan</div>
				<div className="summary-of-benefits-body-top">
					{/* ================================================================================ */}
					<div className="primary-header">Deductible - Ind/Fam</div>
					<div className="info-container">
						<div className="info-container-label">In Network</div>
						<div className="info-container-value">$3,000/$6,000</div>
					</div>
					<div className="info-container">
						<div className="info-container-label">Out of Network</div>

						<div className="info-container-value">$6,000/$12,000</div>
					</div>
					{/* ================================================================================ */}
					<div className="primary-header">Out-of-Pocket - Ind/Fam</div>
					<div className="info-container">
						<div className="info-container-label">In Network</div>
						<div className="info-container-value">$4,000 / $8,000</div>
					</div>
					<div className="info-container">
						<div className="info-container-label">Out of Network</div>
						<div className="info-container-value">$8,000/$16,000</div>
					</div>
					{/* ================================================================================ */}
					<div className="primary-header">Preventive care services</div>
					<div className="info-container">
						<div className="info-container-label">In Network</div>
						<div className="info-container-value">100%</div>
					</div>
					<div className="info-container">
						<div className="info-container-label">Out of Network</div>
						<div className="info-container-value">100%</div>
					</div>

					{/* ================================================================================ */}
					<div className="primary-header">Office Visit</div>
					<div className="info-container">
						<div className="info-container-label">In Network</div>
						<div className="info-container-value">$20 Copay</div>
					</div>
					<div className="info-container">
						<div className="info-container-label">Out of Network</div>
						<div className="info-container-value">50% after deductible</div>
					</div>
					{/* ================================================================================ */}
					<div className="primary-header">Annual routine physical examination</div>
					<div className="info-container">
						<div className="info-container-label">In Network</div>
						<div className="info-container-value">100%</div>
					</div>
					<div className="info-container">
						<div className="info-container-label">Out of Network</div>
						<div className="info-container-value">100%</div>
					</div>
				</div>
				<div className="summary-of-benefits-body-bottom">
					{/* ================================================================================ */}
					<div className="primary-header">Hospital Services</div>
					<div className="info-container">
						<div className="info-container-label">In Network</div>
						<div className="info-container-value">90% after deductible</div>
					</div>
					<div className="info-container">
						<div className="info-container-label">Out of Network</div>
						<div className="info-container-value">50% after deductible</div>
					</div>
					{/* ================================================================================ */}
					<div className="primary-header">Labrotory Sevice</div>
					<div className="info-container">
						<div className="info-container-label">In Network</div>
						<div className="info-container-value">90% after deductible</div>
					</div>
					<div className="info-container">
						<div className="info-container-label">Out of Network</div>
						<div className="info-container-value">50% after deductible</div>
					</div>
					{/* ================================================================================ */}
					<div className="primary-header">Specialist consultation</div>
					<div className="info-container">
						<div className="info-container-label">In Network</div>
						<div className="info-container-value">$60 Copay</div>
					</div>
					<div className="info-container">
						<div className="info-container-label">Out of Network</div>
						<div className="info-container-value">50% after deductible</div>
					</div>
					{/* ================================================================================ */}
					<div className="primary-header">Emergency room facility (Copay waived if admitted)</div>
					<div className="info-container">
						<div className="info-container-label">In Network</div>
						<div className="info-container-value">$75 Copay + 90% after deductible</div>
					</div>
					<div className="info-container">
						<div className="info-container-label">Out of Network</div>
						<div className="info-container-value">$75 Copay + 90% after deductible</div>
					</div>
					{/* ================================================================================ */}
					<div className="primary-header">Outpatients Service</div>
					<div className="info-container">
						<div className="info-container-label">In Network</div>
						<div className="info-container-value">90% after deductible</div>
					</div>
					<div className="info-container">
						<div className="info-container-label">Out of Network</div>
						<div className="info-container-value">50% after deductible</div>
					</div>
					{/* ================================================================================ */}
					<div className="primary-header">Others Service</div>
					<div className="info-container">
						<div className="info-container-label">In Network</div>
						<div className="info-container-value">90% after deductible</div>
					</div>
					<div className="info-container">
						<div className="info-container-label">Out of Network</div>
						<div className="info-container-value">50% after deductible</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SummaryOfProgressiveLaboratoriesPl;
