import React, { useState } from "react";
import "./NoticeBoard.css";
import { IoMdClose } from "react-icons/io";

interface NoticeBoardProps {
	closeNotice: () => void; // Prop to handle the close action from parent
}

const NoticeBoard: React.FC<NoticeBoardProps> = ({ closeNotice }) => {
	const [isVisible, setIsVisible] = useState(true);

	return (
		<div className="notice-board">
			<div className="notice-content">
				<div className="header">
					<h1 className="notice-title">NOTICE</h1>
					<div className="close-model">
						<IoMdClose onClick={closeNotice} />
					</div>
				</div>

				<p className="notice-text">
					<strong>
						NOTICE TO ALL EMPLOYEES ELIGIBLE FOR THE GROUP HEALTH PLAN REGARDING IRS FORM 1095-C
					</strong>
				</p>
				<p className="notice-text">
					PURSUANT TO THE PAPERWORK BURDEN REDUCTION ACT (NEW LAW ENACTED IN DECEMBER 2024) IRS FORM 1095-C
					(EMPLOYER-PROVIDED HEALTH INSURANCE OFFER AND COVERAGE) WILL BE SENT TO INDIVIDUALS ONLY UPON
					REQUEST.   IF YOU WOULD LIKE TO HAVE A COPY OF YOUR FORM 1095-C. 
				</p>
				<p className="notice-text">
					Please contact <strong>Nexcaliber</strong> at:
				</p>
				<div className="contact-info">
					<p>
						📞 <a href="tel:8007410185">(800) 741-0185</a> (Monday-Friday, 8:30 AM - 5:00 PM CST)
					</p>
					<p>
						📧 <a href="mailto:sgal@nexcaliber.com">sgal@nexcaliber.com</a>
					</p>
				</div>
			</div>
		</div>
	);
};

export default NoticeBoard;
