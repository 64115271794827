// import React from "react";
import { useEffect, useState } from "react";
import "./summaryOfBenefitsFormPrint.css";
import { EligibilityResponse } from "../../../../../@types/eligibilityCheck.types";
import SummaryOfHealthPlanFormPrint from "./HealthPlanBop/SummaryOfHealthPlanFormPrint";
import BopDentalPlanSummaryPrint from "./DentalPlanBop/SummaryOfDentalPlanFormPrint";
import BopVisionPlanSummaryPrint from "./VisionPlanBop/SummaryOfVisionPlanFormPrint";
const SummaryOfBenefitsBOPFormPrint = ({ eligibilityReponse }: { eligibilityReponse: EligibilityResponse }) => {
	const [componentsToRender, setComponentsToRender] = useState<JSX.Element[]>([]);

	useEffect(() => {
		if (eligibilityReponse?.result?.[0]) {
			const { ELIGFLAG01, ELIGFLAG02, ELIGFLAG03, OVRPLN, OVRPPOPLN } = eligibilityReponse.result[0];
			const components: JSX.Element[] = [];

			const subPlan = Number(OVRPLN) === 232 && Number(OVRPPOPLN) === 233 ? "copay" : "highDeductible";

			if (ELIGFLAG01 === "T") {
				components.push(<SummaryOfHealthPlanFormPrint key="healthPlan" subPlan={subPlan} />);
			}
			if (ELIGFLAG02 === "T") {
				components.push(<BopDentalPlanSummaryPrint key="dentalPlan" />);
			}
			if (ELIGFLAG03 === "T") {
				components.push(<BopVisionPlanSummaryPrint key="visionPlan" />);
			}

			setComponentsToRender(components);
		}
	}, [eligibilityReponse]);
	return (
		<>
			<div className="">{componentsToRender.length > 0 ? componentsToRender : <div>No plan selected</div>}</div>

			{/* <div className="summary-of-benefits-body-mobile">
				{componentsToRender.length > 0 ? componentsToRender : <div>No plan selected</div>}
			</div> */}
		</>
	);
};

export default SummaryOfBenefitsBOPFormPrint;
