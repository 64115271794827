import { ColumnApi, GridApi, GridReadyEvent } from "ag-grid-community";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import React, { useRef, useState } from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { ICalimStatusDetailGridProps } from "../../../../@types/components/claimStatusDetailsGrid.types";
import { mongoDBDateParserToUSAFormat_v2 } from "../../../../utils/commonFunctions/dates";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IClaimStatusDetails } from "../../../../@types/claimStatus.types";

const ClaimStatusDetailsGrid: React.FC<ICalimStatusDetailGridProps> = ({
	memberDetails,
	claimStatusList
}: any): JSX.Element => {
	const [gridHeight, setGridHeight] = useState("400px");
	const navigate = useNavigate();
	const [agGridAPI, setAgGridAPI] = useState<GridApi | null>(null);
	const [agGridColumnAPI, setAgGridColumnAPI] = useState<ColumnApi | null>(null);
	const agGridRef = useRef<any>(null);

	const handleAgGridReady = (params: GridReadyEvent) => {
		const { api, columnApi } = params;
		setAgGridAPI(Object.assign({}, agGridAPI, api));
		setAgGridColumnAPI(columnApi);
	};

	const handleFirstDataRendered = (params: any) => {
		params.api.sizeColumnsToFit();
		params.columnApi.setColumnWidth("action", "100%", true);
		const { api, columnApi } = params;
		api.sizeColumnsToFit();
	};

	const numberToDollarStr = ({ value }: { value: number }) => {
		console.log("=====numberToDollarStr===", value);
		let strValue = "$ " + String(value);
		if (!strValue.includes(".")) {
			strValue = strValue + ".00";
		}
		return strValue;
	};

	const handleDateCellRenderer = ({ value }: { value: string }) => {
		if (value) {
			return mongoDBDateParserToUSAFormat_v2(value);
		}
		return "NA";
	};
	const handleStatusCellRenderer = ({ value }: { value: string | null }) => {
		console.log("=====>status cell", value);
		if (value) {
			return "PAID";
		}
		return "UNPAID";
	};

	const handleNullValueRenderer = ({ value }: { value: string }) => {
		if (value) {
			return value;
		}
		return "N/A";
	};

	const handleActionCellRender = ({ data }: { data: IClaimStatusDetails }) => {
		const claim_no = window.btoa(String(data.CLMNO));
		const claim_prefix = window.btoa(String(data.CLMPRE));
		const claim_suffix = window.btoa(String(data.CLMSUF));

		const handleNavigateToEOB = () => {
			if (data.EECKNO && data.PRVCKNO) {
				const providerCheckNumber = window.btoa(String(data.PRVCKNO));
				const url = `/claim-status/EOB?gn=${memberDetails.GRPNO}&pckn=${providerCheckNumber}`;
				window.open(url, "_blank");
			} else {
				const url = `/claim-status/EOB?cn=${claim_no}&cp=${claim_prefix}&cs=${claim_suffix}`;
				window.open(url, "_blank");
			}
		};

		return (
			<>
				{data.PRVCKNO ? (
					<Button
						variant="outlined"
						style={{ fontSize: 12, padding: 2, paddingLeft: 5, paddingRight: 5 }}
						onClick={handleNavigateToEOB}
					>
						Provider EOB
					</Button>
				) : (
					"N/A"
				)}
			</>
		);
	};

	const handleEmployeeEOBActionCellRender = ({ data }: { data: IClaimStatusDetails }) => {
		const claim_no = window.btoa(String(data.CLMNO));
		const claim_prefix = window.btoa(String(data.CLMPRE));
		const claim_suffix = window.btoa(String(data.CLMSUF));
		const handleNavigateToEOB = () => {
			if (data.EECKNO && data.PRVCKNO) {
				const employeeCheckNumber = window.btoa(String(data.EECKNO));
				const url = `/claim-status/EOB?gn=${memberDetails.GRPNO}&eckn=${employeeCheckNumber}`;
				window.open(url, "_blank");
			} else {
				const url = `/claim-status/EOB?cn=${claim_no}&cp=${claim_prefix}&cs=${claim_suffix}`;
				window.open(url, "_blank");
			}
		};
		return (
			<>
				{data.EECKNO ? (
					<Button
						variant="outlined"
						style={{ fontSize: 12, padding: 2, paddingLeft: 5, paddingRight: 5 }}
						onClick={handleNavigateToEOB}
					>
						Employee EOB
					</Button>
				) : (
					"N/A"
				)}
			</>
		);
	};

	const claimProcessedData = claimStatusList.map((item: any) => ({
		...item,
		CLM_FULL: `${item.CLMPRE}-${
			String(item.CLMNO).length === 7
				? `00${item.CLMNO}`
				: String(item.CLMNO).length === 8
				? `0${item.CLMNO}`
				: item.CLMNO
		}-${
			item.CLMSUF === 0
				? "0000"
				: String(item.CLMSUF).length === 2
				? `00${item.CLMSUF}`
				: String(item.CLMSUF).length === 3
				? `0${item.CLMSUF}`
				: item.CLMSUF
		}`
	}));

	const columnDefs = [
		{
			field: "FROMDT",
			headerName: "Statement From",
			cellRenderer: "handleDateCellRenderer",
			suppressSizeToFit: true,
			floatingFilterComponentParams: { suppressFilterButton: true }
		},
		{
			field: "THRUDT",
			headerName: "Statement To",
			cellRenderer: "handleDateCellRenderer",
			suppressSizeToFit: true,
			floatingFilterComponentParams: { suppressFilterButton: true }
		},
		{
			field: "NAME",
			headerName: "Provider Name",
			suppressSizeToFit: true,
			floatingFilterComponentParams: { suppressFilterButton: true }
		},
		{
			field: "CLM_FULL",
			headerName: "Claim Number",
			suppressSizeToFit: true,
			floatingFilterComponentParams: { suppressFilterButton: true }
		},
		{
			field: "PDDT",
			headerName: "Status",
			floatingFilterComponentParams: { suppressFilterButton: true },
			suppressSizeToFit: true,
			cellRenderer: "handleStatusCellRenderer"
		},
		{
			field: "PDDT",
			headerName: "Paid Date",
			cellRenderer: "handleDateCellRenderer",
			suppressSizeToFit: true
		},
		{
			field: "CHGAMT",
			headerName: "Total Charges",
			suppressSizeToFit: true,
			cellRenderer: "numberToDollarStr"
		},
		{
			field: "PRVPMT",
			headerName: "Provider Payment",
			cellRenderer: "numberToDollarStr",
			suppressSizeToFit: true,
			filter: false
		},
		{
			field: "PRVCKNO",
			headerName: "Provider Check No",
			cellRenderer: "handleNullValueRenderer",
			suppressSizeToFit: true
		},
		{
			field: "EEPMT",
			headerName: "Employee Payment",
			cellRenderer: "numberToDollarStr",
			suppressSizeToFit: true,
			filter: false
		},
		{
			field: "EECKNO",
			headerName: "Employee Check No",
			cellRenderer: "handleNullValueRenderer",
			suppressSizeToFit: true,
			filter: false
		},
		{
			field: "EMP_RESP",
			headerName: "Employee Responsibility",
			cellRenderer: "numberToDollarStr",
			suppressSizeToFit: true,
			filter: false
		},
		{
			field: "PRV_RESP",
			headerName: "Provider Responsibility",
			cellRenderer: "numberToDollarStr",
			suppressSizeToFit: true,
			filter: false
		},
		{
			field: "action",
			headerName: "Generate Provider EOB",
			cellRenderer: "handleActionCellRender",
			suppressSizeToFit: true,
			filter: false
		},
		{
			field: "action",
			headerName: "Generate Employee EOB",
			cellRenderer: "handleEmployeeEOBActionCellRender",
			suppressSizeToFit: true,
			filter: false
		}
	];

	return (
		<div className="claim-status-details-grid" id="claim-status-details-grid">
			<div className="ag-grid-main-container">
				<div className="ag-theme-alpine" style={{ height: gridHeight, width: "100%" }}>
					<AgGridReact
						ref={agGridRef}
						columnDefs={columnDefs}
						rowData={claimProcessedData}
						onGridReady={handleAgGridReady}
						onFirstDataRendered={handleFirstDataRendered}
						animateRows
						paginationPageSize={6}
						defaultColDef={{
							filter: "agTextColumnFilter",
							floatingFilter: true,
							resizable: true
						}}
						enableCellChangeFlash={true}
						defaultColGroupDef={{ marryChildren: true }}
						frameworkComponents={{
							handleDateCellRenderer,
							handleStatusCellRenderer,
							handleActionCellRender,
							handleEmployeeEOBActionCellRender,
							handleNullValueRenderer,
							numberToDollarStr
						}}
						rowSelection={"single"}
					>
						{/* <AgGridColumn
							field="FROMDT"
							headerName="Statement From"
							cellRenderer="handleDateCellRenderer"
							suppressSizeToFit
							floatingFilterComponentParams={{ suppressFilterButton: true }}
							// onCellClicked={handleNavigateToEOB}
							// cellStyle={() => {
							// 	return { cursor: "pointer" };
							// }}
						></AgGridColumn> */}
						{/* <AgGridColumn
							field="THRUDT"
							headerName="Statement To"
							cellRenderer="handleDateCellRenderer"
							suppressSizeToFit
							floatingFilterComponentParams={{ suppressFilterButton: true }}
						></AgGridColumn> */}
						{/* <AgGridColumn
							field="NAME"
							headerName="Provider Name"
							suppressSizeToFit
							floatingFilterComponentParams={{ suppressFilterButton: true }}
						></AgGridColumn> */}
						{/* <AgGridColumn
							field="CLMNO"
							headerName="Claim Number"
							suppressSizeToFit
							floatingFilterComponentParams={{ suppressFilterButton: true }}
						></AgGridColumn> */}
						{/* <AgGridColumn
							field="PDDT"
							headerName="Status"
							floatingFilterComponentParams={{ suppressFilterButton: true }}
							suppressSizeToFit
							cellRenderer="handleStatusCellRenderer"
						></AgGridColumn> */}
						{/* <AgGridColumn
							field="PDDT"
							headerName="Paid Date"
							cellRenderer="handleDateCellRenderer"
							suppressSizeToFit
							// cellStyle={() => {
							// 	return { cursor: "pointer" };
							// }}
						></AgGridColumn> */}
						{/* <AgGridColumn
							field="CHGAMT"
							headerName="Total Charges"
							// cellRenderer="handleNullValueRenderer"
							suppressSizeToFit
							cellRenderer="numberToDollarStr"
							// cellStyle={() => {
							// 	return { cursor: "pointer" };
							// }}
						></AgGridColumn> */}
						{/* <AgGridColumn
							field="PRVPMT"
							headerName="Provider Payment"
							cellRenderer="numberToDollarStr"
							// cellRenderer="handleNullValueRenderer"
							suppressSizeToFit
							filter={false}
						></AgGridColumn> */}
						{/* <AgGridColumn
							field="PRVCKNO"
							headerName="Provider Check No"
							cellRenderer="handleNullValueRenderer"
							suppressSizeToFit
							// cellStyle={() => {
							// 	return { cursor: "pointer" };
							// }}
						></AgGridColumn> */}
						{/* <AgGridColumn
							field="EEPMT"
							headerName="Employee Payment"
							cellRenderer="numberToDollarStr"
							// cellRenderer="handleNullValueRenderer"
							suppressSizeToFit
							filter={false}
						></AgGridColumn> */}
						{/* <AgGridColumn
							field="EECKNO"
							headerName="Employee Check No"
							cellRenderer="handleNullValueRenderer"
							suppressSizeToFit
							filter={false}
						></AgGridColumn> */}
						{/* <AgGridColumn
							field="EMP_RESP"
							headerName="Employee Responsibility"
							cellRenderer="numberToDollarStr"
							// cellRenderer="handleNullValueRenderer"
							suppressSizeToFit
							filter={false}
						></AgGridColumn> */}
						{/* <AgGridColumn
							field="PRV_RESP"
							headerName="Provider Responsibility"
							cellRenderer="numberToDollarStr"
							// cellRenderer="handleNullValueRenderer"
							suppressSizeToFit
							filter={false}
						></AgGridColumn> */}
						{/* <AgGridColumn
							field="action"
							headerName="Action"
							cellRenderer="handleActionCellRender"
							suppressSizeToFit
							filter={false}
						></AgGridColumn> */}
					</AgGridReact>
				</div>
			</div>
		</div>
	);
};

export default ClaimStatusDetailsGrid;
