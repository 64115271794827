import React from "react";

interface BopHealthPlanSummaryProps {
	subPlan: string;
}

const BopHealthPlanSummary: React.FC<BopHealthPlanSummaryProps> = ({ subPlan }) => {
	return (
		<>
			<div>
				<div className="summary-of-benefits-header">
					Summary of Benefits for Medical Plan {subPlan === "highDeductible" ? "(Deductible)" : "(Copay)"}
				</div>
				<div className="summary-of-benefits-body">
					<div className="summary-of-benefits-sub-header">
						<div className="sub-header-in-network">In Network (You will pay the least)</div>
						<div className="sub-header-out-of-network">Out of Network (You will pay the most)</div>
					</div>
					<div className="body-container-top">
						<div className="column-container">
							<div className="first-column">Deductible - Ind/Fam</div>
							<div className="second-column">
								{subPlan === "highDeductible" ? "$1,650 / $3,300" : "$3,500 / $7,000"}
							</div>
							<div className="third-column">
								Out of network providers are included in the same deductible amount.
							</div>
						</div>
						<div className="column-container">
							<div className="first-column">Out-of-Pocket - Ind/Fam</div>
							<div className="second-column">
								{subPlan === "highDeductible" ? "$3,000 / $6,000" : "$7,500 / $15,000"}
							</div>
							<div className="third-column">
								Out of network providers are included in the same deductible amount.
							</div>
						</div>
						<div className="column-container">
							<div className="first-column">Preventive Care / Screening / Immunization</div>
							<div className="second-column">{subPlan === "highDeductible" ? "100%" : "100%"}</div>
							<div className="third-column">
								{subPlan === "highDeductible" ? "Not Covered" : "Not Covered"}
							</div>
						</div>
						<div className="column-container">
							<div className="first-column">Specialist / Office Visit</div>
							<div className="second-column">
								{subPlan === "highDeductible" ? "20% after deductible" : "$40 copay"}
							</div>
							<div className="third-column">
								{subPlan === "highDeductible" ? "40% after deductible" : "50% after deductible"}
							</div>
						</div>
						<div className="column-container">
							<div className="first-column">Diagnostic Test</div>
							<div className="second-column">
								{subPlan === "highDeductible" ? "20% after deductible" : "30% after deductible"}
							</div>
							<div className="third-column">
								{subPlan === "highDeductible" ? "40% after deductible" : "50% after deductible"}
							</div>
						</div>
					</div>
					<div className="body-container-bottom">
						<div className="column-container">
							<div className="first-column">Hospital Services</div>
							<div className="second-column">
								{subPlan === "highDeductible" ? "20% after deductible" : "30% after deductible"}
							</div>
							<div className="third-column">
								{subPlan === "highDeductible" ? "40% after deductible" : "50% after deductible"}
							</div>
						</div>
						<div className="column-container">
							<div className="first-column">Hospital Stay</div>
							<div className="second-column">
								{subPlan === "highDeductible" ? "20% after deductible" : "20% after deductible"}
							</div>
							<div className="third-column">
								{subPlan === "highDeductible"
									? "40% after deductible, $300 per incident"
									: "50% after deductible, $300 per incident"}
							</div>
						</div>
						<div className="column-container">
							<div className="first-column">Emergency Room Facility</div>
							<div className="second-column">
								{subPlan === "highDeductible"
									? "20% after deductible"
									: "$250 copay, then 30% after deductible"}
							</div>
							<div className="third-column">
								{subPlan === "highDeductible"
									? "40% after deductible"
									: "$250 copay, then 50% after deductible"}
							</div>
						</div>
						<div className="column-container">
							<div className="first-column">Outpatients Services</div>
							<div className="second-column">
								{subPlan === "highDeductible" ? "20% after deductible" : "30% after deductible"}
							</div>
							<div className="third-column">
								{subPlan === "highDeductible" ? "40% after deductible" : "50% co-insurance"}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default BopHealthPlanSummary;
