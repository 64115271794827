/* eslint-disable react/prop-types */
import React, { LegacyRef, RefAttributes, forwardRef } from "react";

import { BiSolidReport } from "react-icons/bi";
import { HiBadgeCheck } from "react-icons/hi";
import PropTypes from "prop-types";

import { Chip } from "@mui/material";

import { FaClipboardList, FaExclamationCircle, FaUserCheck, FaUserEdit } from "react-icons/fa";
import { NEXCALIBER_DETAILS } from "../../../../../constants/nexcaliberDetails";
import { BrookshireBrothersCircleLogo, NexcaliberPurpleLogo } from "../../../../../assets";
import { TbFileReport } from "react-icons/tb";
import "./explanationOfBenefitFormPrint.css";
import { mongoDBDateParserToUSAFormat_v2 } from "../../../../../utils/commonFunctions/dates";
import ExplanationOfBenefitsGridPrint from "./ExplanationOfBenefitsGridPrint/ExplanationOfBenefitsGridPrint";
import Remarks from "../remarks/Remarks";
import { formatSSN } from "../../../../../utils/commonFunctions/SSNMasking";

const ExplanationOfBenefitFormPrint: React.ForwardRefExoticComponent<any> = forwardRef(
	// eslint-disable-next-line react/prop-types
	({ eobDetails }, ref): JSX.Element => {
		return (
			<div className="printable-EOB" ref={ref as unknown as LegacyRef<HTMLDivElement> | undefined}>
				<div className="EOB-paper-container">
					{/* ============================= HEADER CONTAINER ================================= */}
					<div className="header-container">
						<div className="group-logo-container">
							<img src={BrookshireBrothersCircleLogo} className="group-logo" alt="" />
						</div>
						<div className="nexcaliber-logo-container">
							<img src={NexcaliberPurpleLogo} className="nexcaliber-logo" alt="" />
							<div className="nexcaliber-location-details">
								<span>Nexcaliber</span>
								<span>PO BOX 802422, Dallas Texas 75380</span>
								<span>{NEXCALIBER_DETAILS.NEXCALIBER_CONTACT}</span>
							</div>
						</div>
					</div>
					<div className="subheading-main">
						<HiBadgeCheck />
						Explanation of Benefits
					</div>
					{/* ============================= BODY CONTAINER ====================================*/}
					<div className="eob-grid-container">
						<div className="eob-grid-item">
							<div className="category-heading">
								<FaUserCheck />
								<span>Subscriber</span>
							</div>
							<div className="grid-item-fields">
								<span className="field-title">First Name</span>
								<span className="field-value">
									{eobDetails?.claimantSubscriberDetailsInstance[0].INSURED_NAME}
								</span>
							</div>
							<span className="grid-item-fields">
								<div className="grid-item-fields">
									<span className="field-title">Address</span>
									<span className="field-value">
										{eobDetails?.claimantSubscriberDetailsInstance[0].ADDR1} <br />
										{eobDetails?.claimantSubscriberDetailsInstance[0].CITY}
										{","} {eobDetails?.claimantSubscriberDetailsInstance[0].STATE}{" "}
										{eobDetails?.claimantSubscriberDetailsInstance[0].ZIPCODE}
									</span>
								</div>
							</span>
						</div>
						<div className="eob-grid-item">
							<div className="category-heading">
								<FaUserEdit />
								<span>Claimant</span>
							</div>
							<div className="claimant-container">
								<div className="left-section">
									<div className="grid-item-fields">
										<span className="field-title">Claim For</span>
										<span className="field-value">
											{eobDetails?.claimantSubscriberDetailsInstance[0].PATIENT_NAME}
										</span>
									</div>
									{/* <div className="grid-item-fields">
									<span className="field-title">SSN</span>
									<span className="field-value">
										{eobDetails?.claimantSubscriberDetailsInstance[0].SSN
											? formatSSN(String(eobDetails?.claimantSubscriberDetailsInstance[0].SSN))
											: null}
									</span>
								</div> */}

									<div className="grid-item-fields">
										<span className="field-title">Patient Account Number :</span>
										<span className="field-value">
											{eobDetails?.claimantSubscriberDetailsInstance[0].PATACCT}
										</span>
									</div>

									<div className="grid-item-fields">
										<span className="field-title">Claim Number :</span>
										<span className="field-value">
											{eobDetails?.claimantSubscriberDetailsInstance[0].CLAIM_NO}
										</span>
									</div>
								</div>
								<div className="right-section">
									<div className="grid-item-fields">
										<span className="field-title">Check Number:</span>
										<span className="field-value">
											{eobDetails?.claimantSubscriberDetailsInstance[0].CHECKNO}
										</span>
									</div>

									<div className="grid-item-fields">
										<span className="field-title">Paid Date :</span>
										{eobDetails?.claimantSubscriberDetailsInstance[0].PDDT ? (
											<span className="field-value">
												{mongoDBDateParserToUSAFormat_v2(
													eobDetails?.claimantSubscriberDetailsInstance[0].PDDT
												)}
											</span>
										) : null}
									</div>
								</div>
							</div>
						</div>
						<div className="eob-grid-item">
							<div className="category-heading">
								<FaClipboardList />
								<span>Provider Details</span>
							</div>
							<span>{eobDetails?.providerDetailsInstance[0].NAME}</span>
							<span>
								{eobDetails?.providerDetailsInstance[0].ADDR1}
								<br />
								{eobDetails?.providerDetailsInstance[0].CITY}
								{","} {eobDetails?.providerDetailsInstance[0].STATE}{" "}
								{eobDetails?.providerDetailsInstance[0].ZIPCODE}
							</span>
							<br />
							<span>
								{eobDetails?.providerDetailsInstance[0].PRVNO} -{" "}
								{eobDetails?.providerDetailsInstance[0].IDSUFFIX}
							</span>
							<br />
							<div className="category-heading">
								<FaClipboardList />
								<span>PPO Details</span>
							</div>
							<span>{eobDetails?.PPODetailsInstance[0].PPO_DETAILS}</span>
							<br />
							<br />
							<div className="category-heading">
								<FaClipboardList />
								<span>Payee Details</span>
								<Chip
									label={eobDetails?.payeeDetailsInstance[0].PAYTO === "P" ? "Provider" : "Employee"}
									sx={{
										backgroundColor: "#9927dc",
										color: "white",
										marginTop: "5px"
									}}
								/>
							</div>
							<span>{eobDetails?.payeeDetailsInstance[0].PAYEE}</span>
							<span>
								{eobDetails?.payeeDetailsInstance[0].ADDR1}
								<br />
								{eobDetails?.payeeDetailsInstance[0].CITY}
								{","} {eobDetails?.payeeDetailsInstance[0].STATE}{" "}
								{eobDetails?.payeeDetailsInstance[0].ZIP}
							</span>
						</div>
						<div className="eob-grid-item">
							<div className="category-heading">
								<FaExclamationCircle />
								<span>Claim Summary</span>
							</div>
							<div className="grid-item-fields">
								<span className="field-title">Total Amount Covered:</span>
								<span className="field-value">
									$ {eobDetails?.claimSummaryInstance[0]["SUM_ADJCVRD"]}
								</span>
							</div>
							<div className="grid-item-fields">
								<span className="field-title">Paid by Other Insurance Company:</span>
								<span className="field-value">
									$ {eobDetails?.claimSummaryInstance[0]["SUM_COBAMT"]}
								</span>
							</div>
							<div className="grid-item-fields">
								<span className="field-title">Total Paid By Plan:</span>
								<span className="field-value">
									$ {eobDetails?.claimSummaryInstance[0]["SUM_PLNRESP"]}
								</span>
							</div>
							<div className="grid-item-fields">
								<span className="field-title">{"Employee's Responsibility:"}</span>
								<span className="field-value">
									{" "}
									$ {eobDetails?.claimSummaryInstance[0]["SUM_EERESP"]}
								</span>
							</div>
							<div className="grid-item-fields">
								<span className="field-title">Deductable Remaining:</span>
								<span className="field-value"> $ {eobDetails?.deductibleInstance[0]["DEDREMPLN"]}</span>
							</div>
							<div className="grid-item-fields">
								<span className="field-title">Out Of Pocket Remaining Plan:</span>
								<span className="field-value"> $ {eobDetails?.deductibleInstance[0]["OOPREMPLN"]}</span>
							</div>
							<div className="grid-item-fields">
								<span className="field-title">Annual Accumulation:</span>
								<span className="field-value">
									{" "}
									$ {eobDetails?.annualAccumulationInstance[0]["ANN1MET"]}
								</span>
							</div>
						</div>
					</div>
					{/* ============================= CLAIM DETAILS ====================================*/}
					{eobDetails?.claimDetailsInstance.length > 2 ? (
						<>
							<div className="page-break"></div>
							<div className="subheading-main">
								<BiSolidReport />
								Claim Details
							</div>

							<ExplanationOfBenefitsGridPrint
								eligibleMembers={eobDetails?.claimDetailsInstance}
								claimTotal={eobDetails?.claimTotalInstance}
							/>
						</>
					) : (
						<>
							<div className="subheading-main">
								<BiSolidReport />
								Claim Details
							</div>

							<ExplanationOfBenefitsGridPrint
								eligibleMembers={eobDetails?.claimDetailsInstance}
								claimTotal={eobDetails?.claimTotalInstance}
							/>
						</>
					)}

					{/* ============================= REMARKS ====================================*/}
					{eobDetails?.claimDetailsInstance.length >= 1 ? (
						<>
							<div className="page-break"></div>
							<div className="subheading-main" style={{ marginTop: "40px" }}>
								<TbFileReport />
								Remarks
							</div>
							<p style={{ backgroundColor: "#d9d9d9", padding: 2, width: "fit-content" }}>
								{eobDetails?.messageLineInstance[0].FFMSG}
							</p>
							<Remarks remarks_list={eobDetails?.explanationCodesInstance} key={0} />
						</>
					) : (
						<>
							<div style={{ marginTop: "50px" }}>
								<br />
							</div>
							<div className="subheading-main" style={{ marginTop: "40px" }}>
								<TbFileReport />
								Remarks
							</div>
							<p style={{ backgroundColor: "#d9d9d9", padding: 2, width: "fit-content" }}>
								{eobDetails?.messageLineInstance[0].FFMSG}
							</p>
							<Remarks remarks_list={eobDetails?.explanationCodesInstance} key={0} />
						</>
					)}
				</div>
			</div>
		);
	}
);

ExplanationOfBenefitFormPrint.displayName = "EOBPrint";
ExplanationOfBenefitFormPrint.propTypes = {
	eligibleMembers: PropTypes.any.isRequired
};

export default ExplanationOfBenefitFormPrint;
