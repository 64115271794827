/* eslint-disable arrow-parens */
import { FC, Suspense, useCallback, useEffect, useRef, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
	Alert,
	AlertTitle,
	Backdrop,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Chip,
	FormControl,
	FormHelperText,
	Grid,
	IconButton,
	Input,
	InputLabel,
	SpeedDial,
	SpeedDialAction,
	Stack,
	TextField,
	Theme,
	Tooltip,
	alpha,
	createStyles,
	makeStyles,
	useTheme
} from "@mui/material";

import { HiOutlineSearch } from "react-icons/hi";
import ThemeTextField from "../../shared/textField/ThemeTextField";
import EligibilityCheckGrid from "./eligibilityCheckGrid/EligibilityCheckGrid";
import axios from "axios";
import { HiOutlineEmojiSad, HiOutlineEmojiHappy } from "react-icons/hi";
import useRouteParam from "../../../utils/hooks/useQueryParams";
import dayjs, { Dayjs } from "dayjs";
import EligibilityCheckDetailCard from "./eligibilityCheckDetailCard/EligibilityCheckDetailCard";
import InputMask from "react-input-mask";

import "./eligibilityCheck.css";
import {
	EligibilityCheckCoveredEmployee,
	EligibilityCheckEligibleMembers
} from "../../../@types/eligibilityCheck.types";
import { api } from "../../../utils/api";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import {
	dateTimeZoneMaterialUIConverter,
	mongoDBDateParserToUSAFormat_v2,
	monthDayFormatterForWLTQuery,
	monthDayFormatterForWLTQueryForAutoSearch
} from "../../../utils/commonFunctions/dates";
import { roleMapper } from "../../../utils/commonFunctions/roleMapper";
import { relationshipMapper } from "../../../utils/commonFunctions/relationshipMapper";
import { Blocks, Hourglass, ThreeCircles } from "react-loader-spinner";
import SummaryOfBenefits from "./summaryOfBenefits/SummaryOfBenefits";
import { maskSSN } from "../../../utils/commonFunctions/maskSSN";
import { FaEye, FaEyeSlash, FaFileAlt } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";
import { useReactToPrint } from "react-to-print";
import EligibilityCheckFormPrint from "./eligibilityCheckFormPrint/EligibilityCheckFormPrint";
import CoveredDependents from "./coveredDependents/CoveredDependents";
import SummaryOfBenefitsICI from "./summaryOfBenefits_ICI/SummaryOfBenefitsICI";
import SummaryOfBenefitsBOP from "./summaryOfBenefits_BOP/SummaryOfBenefitsBOP";
import SummaryOfProgressiveLaboratoriesPl from "./summaryOfBenefits_PL/SummaryOfBenefitsProgressiveLab";

const EligibilityCheck: FC = (): JSX.Element => {
	// const [dateOfBirth, setDateOfBirth] = useState<Dayjs | null>(dayjs("2022-04-17"));
	const [openSpeedDial, setOpenSpeedDial] = useState<boolean>(false);
	const [loadingState, setLoadingState] = useState(false);

	const [dateOfBirth, setDateOfBirth] = useState<string>("");
	const [passwordVisibility, setPasswordVisibility] = useState<boolean>(false);
	const [storedSSN, setStoredSSN] = useState<string>("");
	const [SSN, setSSN] = useState<string>("");
	const [eligibilityReponse, setEligiblilityReponse] = useState<any>(null);
	const [helper, setHelper] = useState("");
	const [showHideIcon, setShowHideIcon] = useState<boolean>(false);
	const [SSNCharacters, setSSNCharacters] = useState<string[]>([]);
	const [maskedSSN, setMaskedSSN] = useState("");
	const [eligibleStatus, setEligibleStatus] = useState<boolean | null>(null);
	const [eligibleMembers, setEligibleMembers] = useState<EligibilityCheckEligibleMembers>();
	const [coveredEmployee, setCoveredEmployee] = useState<EligibilityCheckCoveredEmployee | null>();
	const queryGroupNumber = useRouteParam("gn");
	const queryDateOfBirth = useRouteParam("db");
	const querySSN = useRouteParam("qs");
	const theme = useTheme();

	const componentRef = useRef(null);
	const handlePrint = useReactToPrint({
		content: () => componentRef.current
	});
	const actions = [{ icon: <FaFileAlt />, name: "Download", handleAction: handlePrint }];

	const handleOpenSpeedDial = () => setOpenSpeedDial(true);
	const handleCloseSpeedDial = () => setOpenSpeedDial(false);

	const handleSSNChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let value = event.target.value;
		console.log(value);

		const lengthOfMasked = maskedSSN.length;
		const lengthOfInputValue = value.length;

		if (value.length > 0 && lengthOfInputValue - lengthOfMasked <= 1) {
			const last = value.length - 1;
			const ch = value.charAt(last);
			console.log("i lmask");

			//showHideIcon changes to false
			if (maskedSSN.length === 0) setShowHideIcon(false);

			if (lengthOfInputValue < lengthOfMasked) {
				if (maskedSSN.endsWith("-") || helper.endsWith("-")) {
					console.log("i dash delete");
					if (helper.endsWith("-")) {
						setHelper((previous) => previous.slice(0, -1));
					}

					if (maskedSSN.endsWith("-")) {
						setMaskedSSN((previous) => previous.slice(0, -1));
					}
					return;
				} else {
					console.log("i am deleting number");

					setMaskedSSN((previous) => previous.slice(0, -1));
					setSSN((previous) => previous.slice(0, -1));
					if (helper.includes("X")) {
						setHelper((previous) => previous.slice(0, -1));
					}

					return;
				}
			}

			// console.log(isNaN(ch)+"  --- "+ch);
			if (
				(isNaN(ch as unknown as number) && lengthOfInputValue > lengthOfMasked) ||
				isNaN(ch as unknown as number) ||
				ch.trim() == ""
			) {
				console.log("i am is nan", ch);
				setMaskedSSN(maskedSSN);
				return;
			} else {
				if (helper.includes("X")) {
					setMaskedSSN(helper);
					setHelper(SSN);
					setShowHideIcon(!showHideIcon);
				}
				if (maskedSSN.length == 11 && value.length >= 11) {
					setMaskedSSN(maskedSSN);
				} else if (maskedSSN.length < 11) {
					if (lengthOfInputValue <= 3) {
						// if(lengthOfMasked<lengthOfInputValue){

						const last = value.charAt(lengthOfInputValue - 1);
						setSSN((previous) => previous + last);
						console.log("i am lengthOfMasked< lengthOfInputValue");
						setMaskedSSN((previous) => previous + "X");
					} else if (lengthOfInputValue <= 6) {
						if (lengthOfInputValue == 4) {
							const last = value.charAt(lengthOfInputValue - 1);
							setSSN((previous) => previous + last);
							console.log("i am lengthOfMasked< lengthOfInputValue");
							setMaskedSSN((previous) => previous + "-X");
						} else if (lengthOfInputValue == 5) {
							const last = value.charAt(lengthOfInputValue - 1);
							setSSN((previous) => previous + last);
							console.log("i am lengthOfMasked< lengthOfInputValue");
							setMaskedSSN((previous) => previous + "X");
						} else if (lengthOfInputValue == 6) {
							const last = value.charAt(lengthOfInputValue - 1);
							setSSN((previous) => previous + last);
							console.log("i am lengthOfMasked< lengthOfInputValue");
							setMaskedSSN((previous) => previous + "X");
						}
					} else {
						if (lengthOfInputValue == 7) {
							const last = value.charAt(lengthOfInputValue - 1);
							setSSN((previous) => previous + last);
							console.log("i am lengthOfMasked< lengthOfInputValue");
							setMaskedSSN((previous) => previous + "-" + last);
						} else {
							const last = value.charAt(lengthOfInputValue - 1);
							setSSN((previous) => previous + last);
							console.log("i am lengthOfMasked< lengthOfInputValue");
							setMaskedSSN((previous) => previous + last);
						}
					}
				} else if (value.length < 11) {
				}
			}
		} else if (value.length > 0 && lengthOfInputValue - lengthOfMasked > 1) {
			console.log("i am paste");
			// let dif=lengthOfInputValue-lengthOfMasked;

			const testIng = value.substring(lengthOfMasked);

			if (isNaN(testIng as unknown as number)) {
				console.log("ami string bujhi naa,full number hami maangi");
				return;
			}

			if (lengthOfMasked <= 3) {
				setSSN((previous) => previous + value.substring(previous.length, value.length > 9 ? 9 : value.length));

				value = value.substring(0, value.length > 9 ? 9 : value.length);

				if (value.length >= 9) {
					const lastNumber = value.substring(5);
					setMaskedSSN(`XXX-XX-${lastNumber}`);
				} else if (value.length >= 5) {
					const lastNumber = value.substring(5);
					setMaskedSSN(`XXX-XX-${lastNumber}`);
				} else if (value.length > 3) {
					if (value.length == 4) {
						setMaskedSSN("XXX-X");
					} else if (value.length == 5) {
						setMaskedSSN("XXX-XX");
					}
				} else {
					if (value.length == 1) {
						setMaskedSSN("X");
					} else if (value.length == 2) {
						setMaskedSSN("XX");
					} else {
						setMaskedSSN("XXX");
					}
				}
			} else if (lengthOfMasked <= 5) {
				setSSN((previous) => previous + value.substring(lengthOfMasked, value.length > 10 ? 10 : value.length));

				value = value.substring(0, value.length > 10 ? 10 : value.length);

				if (value.length >= 10) {
					const lastNumber = value.substring(6);
					setMaskedSSN(`XXX-XX-${lastNumber}`);
				} else if (value.length >= 6) {
					const lastNumber = value.substring(6);
					setMaskedSSN(`XXX-XX-${lastNumber}`);
				} else if (value.length > 4) {
					if (value.length == 4) {
						setMaskedSSN("XXX-");
					} else if (value.length == 5) {
						setMaskedSSN("XXX-X");
					}
				}
			} else if (lengthOfMasked <= 11) {
				setSSN((previous) => previous + value.substring(lengthOfMasked, value.length > 11 ? 11 : value.length));

				value = value.substring(0, value.length > 11 ? 11 : value.length);

				if (value.length >= 11) {
					const lastNumber = value.substring(7);
					setMaskedSSN(`XXX-XX-${lastNumber}`);
				} else if (value.length >= 7) {
					const lastNumber = value.substring(7);
					setMaskedSSN(`XXX-XX-${lastNumber}`);
				}
			} else {
				setMaskedSSN(maskedSSN);
			}
		} else {
			console.log("i am useless else");
			setMaskedSSN("");
			setSSN("");
			setHelper("");
			return;
		}
	};

	const addDashes = (inputString: string) => {
		if (inputString.length <= 3) {
			return inputString;
		}

		const firstThree = inputString.substring(0, 3);
		const remainingChars = inputString.substring(3);

		let result = firstThree;

		if (remainingChars.length >= 2) {
			result += "-" + remainingChars.substring(0, 2) + "-";
			result += remainingChars.substring(2);
		} else {
			result += "-" + remainingChars;
		}

		return result;
	};

	const handleViewClick = () => {
		setShowHideIcon(!showHideIcon);
		if (maskedSSN.length > 0 && maskedSSN.includes("X")) {
			setHelper(maskedSSN);

			const p = addDashes(SSN);

			if (maskedSSN.endsWith("-") && !p.endsWith("-")) {
				setMaskedSSN(p + "-");
			} else if (!maskedSSN.endsWith("-") && p.endsWith("-")) {
				setMaskedSSN(p.slice(0, -1));
			} else {
				setMaskedSSN(p);
			}

			// setMaskedSSN(addDashes(SSN))
		} else {
			setMaskedSSN(helper);
			setHelper(SSN);
		}
	};
	// const handleSSNChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
	// 	// const { value } = event.target;
	// 	// setSSN(value as string);

	// 	const value = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters

	// 	// const checkValue = event.target.value;
	// 	// const dsujgu = checkValue.slice(0, 5).replace(/./g, "X") + checkValue.slice(5);
	// 	// setSSN(dsujgu);
	// 	// }

	// 	// console.log("value", value);
	// 	// const
	// 	// // Format SSN as XXX-XX-XXXX
	// 	// if (value.length > 5) {
	// 	// 	value = value.slice(0, 3) + "-" + value.slice(3, 5) + "-" + value.slice(5);
	// 	// } else if (value.length > 3) {
	// 	// 	value = value.slice(0, 3) + "-" + value.slice(3);
	// 	// }
	// 	// Format SSN as XXX-XX-6789
	// 	// let formattedSSN = "";
	// 	// for (let i = 0; i < value.length; i++) {
	// 	// 	if (i < 5) {
	// 	// 		formattedSSN += "X";
	// 	// 	} else {
	// 	// 		formattedSSN += value[i];
	// 	// 	}

	// 	// 	if (i === 2 || i === 4) {
	// 	// 		formattedSSN += "-";
	// 	// 	}
	// 	// }

	// 	// setSSN(value);
	// }, []);

	// const isNaN = (str: string): boolean => {
	// 	const parsedString = Number(str);
	// 	return parsedString ? true : false;
	// };
	// const handleSSNChange = useCallback(
	// 	(event: React.ChangeEvent<HTMLInputElement>) => {
	// 		const { value } = event.target;

	// 		console.log("input values ", typeof value, value);

	// 		const filteredValue = value.replace(/[\s-]/g, "");
	// 		// Convert filtered value to an array of characters
	// 		const charactersToAdd = filteredValue.split("");

	// 		const validCharacter = isNaN([...charactersToAdd.slice(-1)][0]);

	// 		if (validCharacter) setSSN(value);

	// 		// Update state to only include the last character entered and limit to 9 characters
	// 		if (SSNCharacters.length !== 9 && validCharacter) {
	// 			setSSNCharacters((prevCharacters: any) => {
	// 				const updatedCharacters = [...prevCharacters, ...charactersToAdd.slice(-1)];
	// 				return updatedCharacters.slice(-9);
	// 			});
	// 		}
	// 	},
	// 	[SSNCharacters.length]
	// );

	// const handleSSNChange = (event: React.ChangeEvent<HTMLInputElement>) => {
	// 	const { value } = event.target;
	// 	// if (!isNaN(parseFloat(value))) {
	// 	setSSN(value as string);
	// 	setMaskedSSN(value as string);
	// 	console.log("value", value);
	// 	let maskSSN = "";
	// 	if (value.length < 8) {
	// 		maskSSN = String(value).replace(/[0-9]/g, "X");
	// 	} else {
	// 		maskSSN = value;
	// 	}
	// 	setMaskedSSN(maskSSN as string);
	// 	// }
	// 	// if (/^\d+$/.test(value)) {
	// 	// 	setSSN(value);
	// 	// 	console.log("value", value);
	// 	// 	let maskedSSN = "";
	// 	// 	if (value.length < 8) {
	// 	// 		maskedSSN = value.replace(/\d/g, "X"); // Replace each digit with "X"
	// 	// 	} else {
	// 	// 		maskedSSN = value.replace(/\d(?=\d{4})/g, "X"); // Mask all but the last 4 digits
	// 	// 	}
	// 	// 	setSSN(maskedSSN);
	// 	// }
	// 	// setTimeout(() => {
	// 	// 	const maskedSSN = String(value).replace(/[0-9]/g, "X");
	// 	// }, 1000);
	// };

	// const handleTurnToX = useCallback((_str: string) => {
	// 	setTimeout(() => {

	// 	}, 500)
	// }, []);

	// ====================================================current===================================================
	// const handleSSNChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
	// 	const { value } = event.target;
	// 	const lastChar = value.charAt(value.length - 1);
	// 	console.log("====?char at", lastChar);
	// 	console.log("======?value", Number.isNaN(Number(lastChar)));

	// 	if (value.length <= 11 && (value === "" || !Number.isNaN(Number(lastChar)))) {
	// 		setSSN((prev: string) => prev + lastChar);
	// 		// setSSN(value);
	// 		console.log("ssn value", value);
	// 		if (value.length < 8) {
	// 			setMaskedSSN((prev: string) => prev + handleHideTheString(value));
	// 		} else {
	// 			setMaskedSSN(value);
	// 		}
	// 		console.log("value from input", value, value.length);
	// 	}
	// }, []);

	// const handleHideTheString = (value: string): string => {
	// 	let temp = "";
	// 	console.log("vale len", value.length);
	// 	// for (let i = 0; i < value.length; i++) {
	// 	if (value.length === 4) {
	// 		temp += "-X";
	// 	} else if (value.length === 7) {
	// 		temp += `-${value.charAt(value.length - 1)}`;
	// 	} else {
	// 		console.log("============>first");
	// 		temp += "X";
	// 	}
	// 	// }
	// 	return temp;
	// };

	// useEffect(() => {
	// 	console.log("useEffect is running");
	// 	const getSSNFromState = SSN;
	// 	const result = handleHideTheString(getSSNFromState, getSSNFromState.length);
	// 	console.log("result", result);
	// }, [SSN.length]);

	const handleDateChangeClick = useCallback((value: any) => {
		const date = new Date(value as any);
		console.log("==========date of birth==========", date);
		const _date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
		setDateOfBirth(_date);
	}, []);

	// const handleApproveEnrollmentDateChange = useCallback(
	// 	(date?: MaterialUiPickersDate, value?: string | null | undefined) => {
	// 		const parsedDate = date as unknown as string;
	// 		setApproveEnrollment(Object.assign({}, approveEnrollment, { effective_date: parsedDate }));
	// 	},
	// 	[approveEnrollment]
	// );

	const showSecondCard = () => {
		console.log("===>benifits number", eligibleMembers?.group_number);
		switch (eligibleMembers?.group_number) {
			case 219:
				return <CoveredDependents />;
			case 220:
				return <SummaryOfBenefits />;
			case 188:
				return <SummaryOfBenefitsICI />;
			case 225:
				return <SummaryOfBenefitsBOP eligibilityReponse={eligibilityReponse} />;
			case 300:
				return <SummaryOfProgressiveLaboratoriesPl />;
		}
	};

	const handleAutoSearchEligibilityClick = useCallback(async (SSN: string, DOB: string) => {
		try {
			const SSNUpdate = SSN.replaceAll("-", "");
			setLoadingState(true);

			const DOB1 = monthDayFormatterForWLTQueryForAutoSearch(DOB);
			const eligibilityCheckResponse = await api.eligibilityCheck.getEligibilityCheckDetails(SSNUpdate, DOB1);
			console.log("AutoEligibilityCheckResponse", eligibilityCheckResponse);

			if (eligibilityCheckResponse?.result.length > 0) {
				const {
					ADDR1,
					ADDR2,
					CHILD,
					CITY,
					DEPNO,
					DOB,
					ELIGFLAG01,
					ELIGFLAG02,
					ELIGFLAG03,
					ELIGFLAG04,
					ELIGFLAG05,
					EMPNO,
					FIRSTNAME,
					GRPNO,
					LASTNAME,
					MI,
					OTHER,
					SNAPFROMDT,
					SNAPTHRUDT,
					SPOUSE,
					SSN,
					STATE,
					ZIPCODE
				} = eligibilityCheckResponse?.result[0];

				const eligibleMemberPayload: EligibilityCheckEligibleMembers = {
					group_number: GRPNO ? GRPNO : null,
					member_id: EMPNO ? EMPNO : null,
					role: roleMapper(SPOUSE, CHILD, OTHER),
					first_name: FIRSTNAME ? FIRSTNAME : null,
					middle_name: MI ? MI : "",
					last_name: LASTNAME ? LASTNAME : null,
					date_of_birth: DOB ? mongoDBDateParserToUSAFormat_v2(DOB) : null,
					SSN: SSN ? SSN : null,
					relationship: relationshipMapper(SPOUSE, CHILD, OTHER),
					effective_date: SNAPFROMDT ? mongoDBDateParserToUSAFormat_v2(SNAPFROMDT) : null,
					benefit_end_date: SNAPTHRUDT
						? mongoDBDateParserToUSAFormat_v2(SNAPTHRUDT) === "12-31-2999"
							? "Current"
							: mongoDBDateParserToUSAFormat_v2(SNAPTHRUDT)
						: null,
					address_line_1: ADDR1 ? ADDR1 : null,
					address_line_2: ADDR2 ? ADDR2 : null,
					city: CITY ? CITY : null,
					state: STATE ? STATE : null,
					ZIP: ZIPCODE ? ZIPCODE : null,
					medical: ELIGFLAG01 === "T" ? "Yes" : "No",
					dental: ELIGFLAG02 === "T" ? "Yes" : "No",
					vision: ELIGFLAG03 === "T" ? "Yes" : "No",
					drug: ELIGFLAG04 === "T" ? "Yes" : "No",
					miscellaneous: ELIGFLAG05 === "T" ? "Yes" : "No"
				};

				setEligibleMembers(Object.assign({}, eligibleMemberPayload));

				if (eligibilityCheckResponse?.employeeResult && eligibilityCheckResponse?.employeeResult.length > 0) {
					const { DEPNO, EMPNO, FIRSTNAME, GRPNO, LASTNAME, MI, SSN } =
						eligibilityCheckResponse?.employeeResult[0];

					const coveredEmployeePayload: EligibilityCheckCoveredEmployee = {
						group_number: GRPNO ? GRPNO : null,
						member_id: EMPNO ? EMPNO : null,
						first_name: FIRSTNAME ? FIRSTNAME : null,
						middle_name: MI ? MI : "",
						last_name: LASTNAME ? LASTNAME : null,
						SSN: SSN ? SSN : null
					};

					setCoveredEmployee(Object.assign({}, coveredEmployeePayload));
				} else {
					setCoveredEmployee(null);
				}
				setEligibleStatus(true);
				setLoadingState(false);
			} else {
				setEligibleStatus(false);
			}
			setLoadingState(false);
		} catch (error: any) {
			// Handle error (e.g., log it or show a user-friendly message)
			console.error("Error during eligibility check:", error);
			// Optionally, you can set eligibleStatus to false here based on your error handling logic
			setEligibleStatus(false);
		}
	}, []);

	const handleSearchButtonClick = useCallback(async () => {
		try {
			console.log("Date of Birth", dateOfBirth);

			const SSNUpdate = SSN.replaceAll("-", "");
			setLoadingState(true);
			const DOB = monthDayFormatterForWLTQuery(dateOfBirth);
			// alert(`DOB is ${DOB}`);
			// return;
			const eligibilityCheckResponse = await api.eligibilityCheck.getEligibilityCheckDetails(SSNUpdate, DOB);
			setEligiblilityReponse(eligibilityCheckResponse);
			console.log("eligibilityCheckResponse", eligibilityCheckResponse);

			if (eligibilityCheckResponse?.result.length > 0) {
				const {
					ADDR1,
					ADDR2,
					CHILD,
					CITY,
					DEPNO,
					DOB,
					ELIGFLAG01,
					ELIGFLAG02,
					ELIGFLAG03,
					ELIGFLAG04,
					ELIGFLAG05,
					EMPNO,
					FIRSTNAME,
					GRPNO,
					LASTNAME,
					MI,
					OTHER,
					SNAPFROMDT,
					SNAPTHRUDT,
					SPOUSE,
					SSN,
					STATE,
					ZIPCODE
				} = eligibilityCheckResponse?.result[0];

				const eligibleMemberPayload: EligibilityCheckEligibleMembers = {
					group_number: GRPNO ? GRPNO : null,
					member_id: EMPNO ? EMPNO : null,
					role: roleMapper(SPOUSE, CHILD, OTHER),
					first_name: FIRSTNAME ? FIRSTNAME : null,
					middle_name: MI ? MI : "",
					last_name: LASTNAME ? LASTNAME : null,
					date_of_birth: DOB ? mongoDBDateParserToUSAFormat_v2(DOB) : null,
					SSN: SSN ? SSN : null,
					relationship: relationshipMapper(SPOUSE, CHILD, OTHER),
					effective_date: SNAPFROMDT ? mongoDBDateParserToUSAFormat_v2(SNAPFROMDT) : null,
					benefit_end_date: SNAPTHRUDT
						? mongoDBDateParserToUSAFormat_v2(SNAPTHRUDT) === "12-31-2999"
							? "Current"
							: mongoDBDateParserToUSAFormat_v2(SNAPTHRUDT)
						: null,
					address_line_1: ADDR1 ? ADDR1 : null,
					address_line_2: ADDR2 ? ADDR2 : null,
					city: CITY ? CITY : null,
					state: STATE ? STATE : null,
					ZIP: ZIPCODE ? ZIPCODE : null,
					medical: ELIGFLAG01 === "T" ? "Yes" : "No",
					dental: ELIGFLAG02 === "T" ? "Yes" : "No",
					vision: ELIGFLAG03 === "T" ? "Yes" : "No",
					drug: ELIGFLAG04 === "T" ? "Yes" : "No",
					miscellaneous: ELIGFLAG05 === "T" ? "Yes" : "No"
				};

				setEligibleMembers(Object.assign({}, eligibleMemberPayload));

				if (eligibilityCheckResponse?.employeeResult && eligibilityCheckResponse?.employeeResult.length > 0) {
					const { DEPNO, EMPNO, FIRSTNAME, GRPNO, LASTNAME, MI, SSN } =
						eligibilityCheckResponse?.employeeResult[0];

					const coveredEmployeePayload: EligibilityCheckCoveredEmployee = {
						group_number: GRPNO ? GRPNO : null,
						member_id: EMPNO ? EMPNO : null,
						first_name: FIRSTNAME ? FIRSTNAME : null,
						middle_name: MI ? MI : "",
						last_name: LASTNAME ? LASTNAME : null,
						SSN: SSN ? SSN : null
					};

					setCoveredEmployee(Object.assign({}, coveredEmployeePayload));
				} else {
					setCoveredEmployee(null);
				}
				setEligibleStatus(true);
				setLoadingState(false);
			} else {
				setEligibleStatus(false);
			}
			setLoadingState(false);
		} catch (error: any) {
			// Handle error (e.g., log it or show a user-friendly message)
			console.error("Error during eligibility check:", error);
			// Optionally, you can set eligibleStatus to false here based on your error handling logic
			setEligibleStatus(false);
		}
	}, [SSN, dateOfBirth]);

	// const handleShowSSNField = useCallback(
	// 	(_state: boolean) => {
	// 		setPasswordVisibility(_state);
	// 		if (_state) setStoredSSN(SSN);
	// 	},
	// 	[SSN]
	// );

	// const wrappedValue = (_SSN: string): string => {
	// 	let processedSSN = "";
	// 	if (_SSN.length < 8) processedSSN = _SSN.replace(/[0-9]/g, "X");
	// 	else processedSSN = _SSN;
	// 	return processedSSN;
	// };

	console.log("SSN ===>", SSN);
	console.log("maskedSSN-SSN ===>", maskedSSN);
	console.log("SSN Character ===>", SSNCharacters);
	console.log("Store SSN ===>", storedSSN);

	// useEffect(() => {
	// 	passwordVisibility ? setSSN(maskedSSN) : setSSN(storedSSN);
	// }, [passwordVisibility, storedSSN]);

	useEffect(() => {
		if (queryGroupNumber && queryDateOfBirth && querySSN) {
			setSSN(querySSN as string);

			// const date = new Date(queryDateOfBirth as any);
			// const _date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
			// setDateOfBirth(queryDateOfBirth as unknown as string);

			const formatQueryDate = String(queryDateOfBirth).split("T")[0];
			setDateOfBirth(formatQueryDate as unknown as string);
			handleAutoSearchEligibilityClick(querySSN as unknown as string, queryDateOfBirth as unknown as string);
		}
	}, [handleAutoSearchEligibilityClick, queryDateOfBirth, queryGroupNumber, querySSN]);

	// useEffect(() => {
	// 	const handleKeyDown = (event: KeyboardEvent) => {
	// 		if (event.key === "Backspace" && event.target === document.activeElement) {
	// 			event.preventDefault();
	// 			console.log("Backspace key pressed");
	// 			setSSNCharacters((prevCharacters: string[]) => prevCharacters.slice(0, -1));
	// 			SSN.slice(-1) === "-"
	// 				? setSSN((prev: string) => prev.slice(0, -2))
	// 				: setSSN((prev: string) => prev.slice(0, -1));
	// 			setMaskedSSN((prev: string) => prev.slice(0, -1));
	// 		}
	// 	};

	// 	document.addEventListener("keydown", handleKeyDown);

	// 	return () => {
	// 		document.removeEventListener("keydown", handleKeyDown);
	// 	};
	// }, [SSN, SSNCharacters]);

	// useEffect(() => {
	// 	const parsedSSN = SSNCharacters.join("");
	// 	setMaskedSSN(parsedSSN);
	// }, [SSNCharacters]);

	//====================================================================

	console.log("Console for the proos--->", eligibilityReponse);
	return (
		<div className="eligibility-check">
			<div className="theme-header-1 header">Eligibility and Benefit Validation</div>
			<Card className={"card"}>
				<CardContent className="card-content-container">
					<FormControl variant="standard" fullWidth>
						<InputLabel shrink htmlFor="bootstrap-input">
							Member ID
						</InputLabel>
						<ThemeTextField
							fullWidth
							endAdornment={
								maskedSSN.length > 0 && (
									<Tooltip title={showHideIcon ? "Hide" : "Show"} arrow>
										<div
											onClick={handleViewClick}
											style={{
												position: "absolute",
												left: "90%",
												display: "flex",
												alignItems: "center",
												cursor: "pointer"
											}}
										>
											{showHideIcon ? <FaEye /> : <FaEyeSlash />}
										</div>
									</Tooltip>
								)
							}
							value={maskedSSN}
							// onChange={handleSSNChange}
							onInput={handleSSNChange}
						/>
						{/* <span className="show-hide-ssn-container">
							<FaEye />
						</span> */}
						{/* <InputMask
							mask="***-**-****"
							maskChar={null}
							value={wrappedValue(SSN)}
							onChange={handleSSNChange}
						>
							{(inputProps: any) => <ThemeTextField helper fullWidth {...inputProps} />}
						</InputMask> */}
						{/* <InputMask
							mask="999-99-9999"
							maskChar={null}
							value={maskedSSN}
							// value="123456789"
							onChange={handleSSNChange}
						>
						{(inputProps: any) => (
								<TextField {...inputProps} label="SSN" variant="outlined" fullWidth />
							)}
						{(inputProps: any) => <ThemeTextField helper {...inputProps} />} 
						 </InputMask> */}
						{/* <ThemeTextField value={maskedSSN} placeholder="999-99-9999" onChange={handleSSNChange} /> */}
						<FormHelperText className="ssn-helper-text" sx={{ fontSize: "0.7rem", color: "#595959" }}>
							SSN will be found from Member ID Card
						</FormHelperText>
					</FormControl>
					<FormControl variant="standard" fullWidth style={{ marginLeft: "2rem" }}>
						<InputLabel shrink htmlFor="bootstrap-input">
							Date of Birth
						</InputLabel>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DesktopDatePicker
								onChange={handleDateChangeClick}
								defaultValue={dateOfBirth ? dayjs(dateOfBirth) : null}
								sx={{
									width: "100%",
									"label + &": {
										marginTop: theme.spacing(3)
									},
									"& .MuiInputBase-input": {
										//borderRadius: 4,
										position: "relative",
										backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
										border: "1px solid",
										borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
										fontSize: 16,
										width: "100%",
										padding: "10px 12px",
										transition: theme.transitions.create([
											"border-color",
											"background-color",
											"box-shadow"
										]),
										// Use the system font instead of the default Roboto font.

										"&:focus": {
											boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
											borderColor: theme.palette.primary.main
										}
									}
								}}
							/>
						</LocalizationProvider>
					</FormControl>
					<IconButton
						onClick={handleSearchButtonClick}
						style={{
							height: "40px",
							width: "40px",
							marginLeft: "1rem",
							marginTop: "1.5rem",
							background: "#9a2ed5",
							borderRadius: "8px"
						}}
					>
						<HiOutlineSearch style={{ color: "white" }} />
					</IconButton>
					{/* <IconButton
						onClick={handleViewClick}
						style={{
							height: "40px",
							width: "40px",
							marginLeft: "1rem",
							marginTop: "1.5rem",
							background: "red",
							borderRadius: "8px"
						}}
					>
						<HiOutlineSearch style={{ color: "white" }} />
					</IconButton> */}
				</CardContent>
			</Card>
			<Card className="mobile-view-card">
				<CardContent className="card-content-container">
					<FormControl variant="standard" fullWidth>
						<InputLabel shrink htmlFor="bootstrap-input">
							Member ID
						</InputLabel>
						<ThemeTextField
							fullWidth
							endAdornment={
								maskedSSN.length > 0 && (
									<Tooltip title={showHideIcon ? "Hide" : "Show"} arrow>
										<div
											onClick={handleViewClick}
											style={{
												position: "absolute",
												left: "90%",
												display: "flex",
												alignItems: "center",
												cursor: "pointer"
											}}
										>
											{showHideIcon ? <FaEye /> : <FaEyeSlash />}
										</div>
									</Tooltip>
								)
							}
							value={maskedSSN}
							onInput={handleSSNChange}
							placeholder="Enter the SSN"
						/>

						<FormHelperText className="ssn-helper-text" sx={{ fontSize: "0.7rem", color: "#595959" }}>
							SSN will be found from Member ID Card
						</FormHelperText>
					</FormControl>
					<FormControl variant="standard" fullWidth style={{ marginTop: "12px" }}>
						<InputLabel shrink htmlFor="bootstrap-input">
							Date of Birth
						</InputLabel>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DesktopDatePicker
								onChange={handleDateChangeClick}
								defaultValue={dateOfBirth ? dayjs(dateOfBirth) : null}
								sx={{
									width: "100%",
									"label + &": {
										marginTop: theme.spacing(3)
									},
									"& .MuiInputBase-input": {
										//borderRadius: 4,
										position: "relative",
										backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
										border: "1px solid",
										borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
										fontSize: 16,
										width: "100%",
										padding: "10px 12px",
										transition: theme.transitions.create([
											"border-color",
											"background-color",
											"box-shadow"
										]),

										"&:focus": {
											boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
											borderColor: theme.palette.primary.main
										}
									}
								}}
							/>
						</LocalizationProvider>
					</FormControl>
					<IconButton
						onClick={handleSearchButtonClick}
						style={{
							marginTop: "1.5rem",
							background: "#9a2ed5",
							borderRadius: "8px",
							fontSize: 16,
							color: "white"
						}}
					>
						Check for Elgibility
					</IconButton>
				</CardContent>
			</Card>

			<div className="alert-container">
				{eligibleStatus === true ? (
					<div className="alert-content-container">
						<HiOutlineEmojiHappy className="alert-content-icons" size={40} />
						<div className="text-content">
							<h3>Search Successful</h3>
							<p>
								<strong>Eligibility:</strong> Member is Eligible
							</p>
						</div>
					</div>
				) : eligibleStatus === false ? (
					<div className="alert-content-container-fail">
						<HiOutlineEmojiSad className="alert-content-icons" size={40} />
						<div className="text-content">
							<h3>Sorry!</h3>
							<p>
								<strong>Eligibility:</strong> Member is not Eligible
							</p>
						</div>
					</div>
				) : (
					<></>
				)}
			</div>
			{eligibleStatus && eligibleMembers ? (
				<>
					<Alert severity="warning" sx={{ fontSize: "1rem" }} className="disclaimer-container">
						<strong className="disclaimer">Disclaimer:</strong> The information provided here is for general
						informational purposes only. It does not constitute professional advice. Any reliance you place
						on such information is strictly at your own risk. Always seek the advice of a qualified
						professional for any concerns you may have. We do not endorse or guarantee the accuracy,
						completeness, or suitability of the information provided.
					</Alert>

					<EligibilityCheckDetailCard cardDetailProp={eligibleMembers} coveredEmployee={coveredEmployee} />
					{showSecondCard()}
					<Backdrop open={openSpeedDial} style={{ zIndex: 1009 }} />
					<SpeedDial
						ariaLabel="SpeedDial basic example"
						// sx={{ position: "absolute", bottom: 16, right: 16 }}
						style={{
							position: "fixed",
							bottom: 16,
							right: 16
						}}
						sx={{
							"& .MuiFab-primary": { backgroundColor: "#9a2ed5" },
							"& .MuiFab-primary:hover": { backgroundColor: "#9a2ed5" }
						}}
						icon={<FaPlus />}
						onOpen={handleOpenSpeedDial}
						onClose={handleCloseSpeedDial}
						open={openSpeedDial}
					>
						{actions.map((action: any) => (
							<SpeedDialAction
								key={action.name}
								icon={action.icon}
								tooltipTitle={action.name}
								tooltipOpen
								onClick={action.handleAction}
							/>
						))}
					</SpeedDial>
					<div style={{ display: "none" }}>
						<Suspense fallback={<div />}>
							<EligibilityCheckFormPrint
								eligibleMembers={eligibleMembers}
								coveredEmployee={coveredEmployee}
								eligibilityReponse={eligibilityReponse}
								ref={componentRef}
							/>
						</Suspense>
					</div>
				</>
			) : null}
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme: Theme) => theme.zIndex.drawer + 1,
					position: "absolute"
				}}
				open={loadingState}
			>
				<Hourglass
					visible={true}
					height="8%"
					width="8%"
					ariaLabel="hourglass-loading"
					wrapperStyle={{}}
					wrapperClass=""
					colors={["#b61aff", "#4631ce"]}
				/>
			</Backdrop>
		</div>
	);
};

export default EligibilityCheck;
