import React, { Suspense, useCallback, useEffect, useRef, useState } from "react";
import { BrookshireBrothersCircleLogo, NexcaliberPurpleLogo, Nlogo } from "../../../../assets";
import { NEXCALIBER_DETAILS } from "../../../../constants/nexcaliberDetails";
import {
	FaClipboardList,
	FaExclamationCircle,
	FaFileAlt,
	FaSave,
	FaUserCheck,
	FaUserEdit,
	FaPlus
} from "react-icons/fa";
import { HiBadgeCheck } from "react-icons/hi";
import { BiSolidReport } from "react-icons/bi";
import { TbFileReport } from "react-icons/tb";
import { useReactToPrint } from "react-to-print";

import ExplanationOfBenefitsGrid from "./explanationOfBenefitsGrid/ExplanationOfBenefitsGrid";

import ExplanationOfBenefitFormPrint from "./explanationOfBenefitFormPrint/ExplanationOfBenefitFormPrint";

import "./explanationOfBenefits.css";

import { Chip, Backdrop, SpeedDial, SpeedDialAction } from "@mui/material";
import useQuery from "../../../../utils/hooks/useQuery";
import { mongoDBDateParserToUSAFormat_v2 } from "../../../../utils/commonFunctions/dates";
import { api } from "../../../../utils/api";
import Remarks from "./remarks/Remarks";
import { formatSSN } from "../../../../utils/commonFunctions/SSNMasking";
import { ExplanationOfBenefitsSkeleton, LogoLoader } from "../../../shared";

const ExplanationOfBenefits: React.FC = (): JSX.Element => {
	const query = useQuery();
	const [claimNo, setClaimNo] = useState<number | null>(null);
	const [claimPrefix, setClaimPrefix] = useState<number | null>(null);
	const [claimSuffix, setClaimSuffix] = useState<number | null>(null);
	const [checkNumber, setCheckNumber] = useState<number | null>(null);
	const [groupNumber, setGroupNumber] = useState<number | null>(null);
	const [openSpeedDial, setOpenSpeedDial] = useState<boolean>(false);
	const [eobDetails, setEobDetails] = useState<any>();
	const [loading, setLoading] = useState<boolean>(false);
	const [openBackdrop, setOpenBackdrop] = useState<boolean>(true);

	console.log("========eobDetails=======", eobDetails);

	const componentRef = useRef(null);
	const handlePrint = useReactToPrint({
		content: () => componentRef.current
	});

	const actions = [{ icon: <FaFileAlt />, name: "Download", handleAction: handlePrint }];

	const handleOpenSpeedDial = () => setOpenSpeedDial(true);
	const handleCloseSpeedDial = () => setOpenSpeedDial(false);

	const getEOBDetailsWithCheckNumber = useCallback(async () => {
		if (checkNumber !== null && groupNumber !== null) {
			setLoading(true);
			const eobDetailsInstance = await api.claimStatus.getEOBDetailsWithCheckNumber(
				Number(groupNumber),
				Number(checkNumber)
			);
			setEobDetails(eobDetailsInstance);
			setLoading(false);
		}
	}, [checkNumber, groupNumber]);

	const getEOBDetails_v3 = useCallback(async () => {
		if (claimNo !== null && claimPrefix !== null && claimSuffix !== null) {
			setLoading(true);
			const eobDetailsInstance = await api.claimStatus.getEOBDetailsV3(
				String(claimNo),
				String(claimPrefix),
				String(claimSuffix)
			);
			setEobDetails(eobDetailsInstance);
			setLoading(false);
		}
	}, [claimNo, claimPrefix, claimSuffix]);

	// const getEOBDetails = useCallback(async () => {
	// 	if (claimNo !== null && claimPrefix !== null && claimSuffix !== null) {
	// 		const eobDetailsInstance = await api.claimStatus.getEOBDetails(
	// 			String(claimNo),
	// 			String(claimPrefix),
	// 			String(claimSuffix)
	// 		);
	// 		setEobDetails(eobDetailsInstance);
	// 	}
	// }, [claimPrefix, claimNo, claimSuffix]);

	useEffect(() => {
		if (query && query.get("cn") && query.get("cp") && query.get("cs")) {
			const encoded_claim_no = query.get("cn");
			const encoded_claim_prefix = query.get("cp");
			const encoded_claim_suffix = query.get("cs");
			if (encoded_claim_no && encoded_claim_prefix && encoded_claim_suffix) {
				const claim_no = window.atob(encoded_claim_no);
				const claim_prefix = window.atob(encoded_claim_prefix);
				const claim_suffix = window.atob(encoded_claim_suffix);
				setClaimNo(Number(claim_no));
				setClaimPrefix(Number(claim_prefix));
				setClaimSuffix(Number(claim_suffix));
			}
		} else if (query && query.get("pckn") && query.get("gn")) {
			const provider_encoded_check_number = query.get("pckn");
			const groupNumber = query.get("gn");
			if (provider_encoded_check_number) {
				const checkNumber = window.atob(provider_encoded_check_number);
				setCheckNumber(Number(checkNumber));
				setGroupNumber(Number(groupNumber));
			}
		} else if (query && query.get("eckn") && query.get("gn")) {
			const employee_encoded_check_number = query.get("eckn");
			const groupNumber = query.get("gn");
			if (employee_encoded_check_number) {
				const checkNumber = window.atob(employee_encoded_check_number);
				setCheckNumber(Number(checkNumber));
				setGroupNumber(Number(groupNumber));
			}
		}
	}, [query]);

	useEffect(() => {
		if (query && query.get("cn") && query.get("cp") && query.get("cs")) {
			getEOBDetails_v3();
		} else if (query && query.get("pckn") && query.get("gn")) {
			// For Providers Query
			getEOBDetailsWithCheckNumber();
		} else if (query && query.get("eckn") && query.get("gn")) {
			// For Providers Query
			getEOBDetailsWithCheckNumber();
		}
	}, [getEOBDetailsWithCheckNumber, getEOBDetails_v3, query]);

	// console.log("====>eob details", eobDetails);

	return (
		<>
			{loading ? (
				<>
					<Backdrop
						sx={{ color: "#fff", zIndex: "1000" }}
						open={openBackdrop}
						// onClick={handleClose}
					>
						<LogoLoader logoSrc={Nlogo} />
					</Backdrop>
					{/*  */}
					<ExplanationOfBenefitsSkeleton />
				</>
			) : (
				<div className="EOB-main" id="EOB-main">
					<Backdrop open={openSpeedDial} style={{ zIndex: 1009 }} />
					<SpeedDial
						ariaLabel="SpeedDial basic example"
						// sx={{ position: "absolute", bottom: 16, right: 16 }}
						style={{
							position: "fixed",
							bottom: 16,
							right: 16
						}}
						icon={<FaPlus style={{ color: "#ffff" }} />}
						onOpen={handleOpenSpeedDial}
						onClose={handleCloseSpeedDial}
						open={openSpeedDial}
						FabProps={{
							style: {
								backgroundColor: "#9b35ce"
							}
						}}
					>
						{actions.map((action: any) => (
							<SpeedDialAction
								key={action.name}
								icon={action.icon}
								tooltipTitle={action.name}
								tooltipOpen
								onClick={action.handleAction}
							/>
						))}
					</SpeedDial>
					<span className="theme-header-1 header">Explanation Of Benefits (EOB)</span>
					<div className="EOB-paper-container">
						{/* ============================= HEADER CONTAINER ================================= */}
						<div className="header-container">
							<div className="group-logo-container">
								<img src={BrookshireBrothersCircleLogo} className="group-logo" alt="" />
							</div>
							<div className="nexcaliber-logo-container">
								<img src={NexcaliberPurpleLogo} className="nexcaliber-logo" alt="" />
								<div className="nexcaliber-location-details">
									<span>Nexcaliber</span>
									<span>PO BOX 802422, Dallas Texas 75380</span>
									<span>{NEXCALIBER_DETAILS.NEXCALIBER_CONTACT}</span>
								</div>
							</div>
						</div>
						<div className="subheading-main">
							<HiBadgeCheck />
							Explanation of Benefits
						</div>
						{/* ============================= BODY CONTAINER ====================================*/}
						<div className="eob-grid-container">
							<div className="eob-grid-item">
								<div className="category-heading">
									<FaUserCheck />
									<span>Subscriber</span>
								</div>
								<div className="grid-item-fields">
									<span className="field-title">Name</span>
									<span className="field-value">
										{eobDetails?.claimantSubscriberDetailsInstance[0].INSURED_NAME}
									</span>
								</div>
								<span className="grid-item-fields">
									<div className="grid-item-fields">
										<span className="field-title">Address</span>
										<span className="field-value">
											{eobDetails?.claimantSubscriberDetailsInstance[0].ADDR1} <br />
											{eobDetails?.claimantSubscriberDetailsInstance[0].CITY}
											{","} {eobDetails?.claimantSubscriberDetailsInstance[0].STATE}{" "}
											{eobDetails?.claimantSubscriberDetailsInstance[0].ZIPCODE}
										</span>
									</div>
								</span>
							</div>
							<div className="eob-grid-item">
								<div className="category-heading">
									<FaUserEdit />
									<span>Claimant</span>
								</div>
								<div className="claimant-container">
									<div className="left-section">
										<div className="grid-item-fields">
											<span className="field-title">Claim For</span>
											<span className="field-value">
												{eobDetails?.claimantSubscriberDetailsInstance[0].PATIENT_NAME}
											</span>
										</div>
										{/* <div className="grid-item-fields">
									<span className="field-title">SSN</span>
									<span className="field-value">
										{eobDetails?.claimantSubscriberDetailsInstance[0].SSN
											? formatSSN(String(eobDetails?.claimantSubscriberDetailsInstance[0].SSN))
											: null}
									</span>
								</div> */}

										<div className="grid-item-fields">
											<span className="field-title">Patient Account Number :</span>
											<span className="field-value">
												{eobDetails?.claimantSubscriberDetailsInstance[0].PATACCT}
											</span>
										</div>

										<div className="grid-item-fields">
											<span className="field-title">Claim Number :</span>
											<span className="field-value">
												{eobDetails?.claimantSubscriberDetailsInstance[0].CLAIM_NO}
											</span>
										</div>
									</div>
									<div className="right-section">
										<div className="grid-item-fields">
											<span className="field-title">Check Number:</span>
											<span className="field-value">
												{eobDetails?.claimantSubscriberDetailsInstance[0].CHECKNO}
											</span>
										</div>

										<div className="grid-item-fields">
											<span className="field-title">Paid Date :</span>
											{eobDetails?.claimantSubscriberDetailsInstance[0].PDDT ? (
												<span className="field-value">
													{mongoDBDateParserToUSAFormat_v2(
														eobDetails?.claimantSubscriberDetailsInstance[0].PDDT
													)}
												</span>
											) : null}
										</div>
									</div>
								</div>
							</div>
							<div className="eob-grid-item">
								<div className="category-heading">
									<FaClipboardList />
									<span>Provider Details</span>
								</div>
								<span>{eobDetails?.providerDetailsInstance[0].NAME}</span>
								<span>
									{eobDetails?.providerDetailsInstance[0].ADDR1}
									<br />
									{eobDetails?.providerDetailsInstance[0].CITY}
									{","} {eobDetails?.providerDetailsInstance[0].STATE}{" "}
									{eobDetails?.providerDetailsInstance[0].ZIPCODE}
								</span>
								<br />
								<span>
									{eobDetails?.providerDetailsInstance[0].PRVNO} -{" "}
									{eobDetails?.providerDetailsInstance[0].IDSUFFIX}
								</span>
								<br />
								<div className="category-heading">
									<FaClipboardList />
									<span>PPO Details</span>
								</div>
								<span>{eobDetails?.PPODetailsInstance[0].PPO_DETAILS}</span>
								<br />
								<br />
								<div className="category-heading">
									<FaClipboardList />
									<span>Payee Details</span>
									<Chip
										label={
											eobDetails?.payeeDetailsInstance[0].PAYTO === "P" ? "Provider" : "Employee"
										}
										sx={{
											backgroundColor: "#9927dc",
											color: "white",
											marginTop: "5px"
										}}
									/>
								</div>
								<span>{eobDetails?.payeeDetailsInstance[0].PAYEE}</span>
								<span>
									{eobDetails?.payeeDetailsInstance[0].ADDR1}
									<br />
									{eobDetails?.payeeDetailsInstance[0].CITY}
									{","} {eobDetails?.payeeDetailsInstance[0].STATE}{" "}
									{eobDetails?.payeeDetailsInstance[0].ZIP}
								</span>
							</div>
							<div className="eob-grid-item">
								<div className="category-heading">
									<FaExclamationCircle />
									<span>Claim Summary</span>
								</div>
								<div className="grid-item-fields">
									<span className="field-title">Total Amount Covered:</span>
									<span className="field-value">
										$ {eobDetails?.claimSummaryInstance[0]["SUM_ADJCVRD"]}
									</span>
								</div>
								<div className="grid-item-fields">
									<span className="field-title">Paid by Other Insurance Company:</span>
									<span className="field-value">
										$ {eobDetails?.claimSummaryInstance[0]["SUM_COBAMT"]}
									</span>
								</div>
								<div className="grid-item-fields">
									<span className="field-title">Total Paid By Plan:</span>
									<span className="field-value">
										$ {eobDetails?.claimSummaryInstance[0]["SUM_PLNRESP"]}
									</span>
								</div>
								<div className="grid-item-fields">
									<span className="field-title">{"Employee's Responsibility:"}</span>
									<span className="field-value">
										{" "}
										$ {eobDetails?.claimSummaryInstance[0]["SUM_EERESP"]}
									</span>
								</div>
								<div className="grid-item-fields">
									<span className="field-title">Deductable Remaining:</span>
									<span className="field-value">
										{" "}
										$ {eobDetails?.deductibleInstance[0]["DEDREMPLN"]}
									</span>
								</div>
								<div className="grid-item-fields">
									<span className="field-title">Out Of Pocket Remaining Plan:</span>
									<span className="field-value">
										{" "}
										$ {eobDetails?.deductibleInstance[0]["OOPREMPLN"]}
									</span>
								</div>
								<div className="grid-item-fields">
									<span className="field-title">Annual Accumulation:</span>
									<span className="field-value">
										{" "}
										$ {eobDetails?.annualAccumulationInstance[0]["ANN1MET"]}
									</span>
								</div>
							</div>
						</div>
						{/* ============================= CLAIM DETAILS ====================================*/}
						<div className="subheading-main">
							<BiSolidReport />
							Claim Details
						</div>

						<ExplanationOfBenefitsGrid
							eligibleMembers={eobDetails?.claimDetailsInstance}
							claimTotal={eobDetails?.claimTotalInstance}
						/>

						{/* ============================= REMARKS ====================================*/}
						<div className="subheading-main">
							<TbFileReport />
							Remarks
						</div>
						<p style={{ backgroundColor: "#d9d9d9", padding: 2, width: "fit-content" }}>
							{eobDetails?.messageLineInstance[0].FFMSG}
						</p>
						<Remarks remarks_list={eobDetails?.explanationCodesInstance} key={0} />
					</div>
					<div style={{ display: "none" }}>
						<Suspense fallback={<div />}>
							{eobDetails ? (
								<ExplanationOfBenefitFormPrint eobDetails={eobDetails} ref={componentRef} />
							) : null}
						</Suspense>
					</div>
				</div>
			)}
		</>
	);
};

export default ExplanationOfBenefits;
