import React from "react";
import "./dentalPlanSummaryStyles.css";

const BopDentalPlanSummary = () => {
	return (
		<>
			<div className="dentalsummary">
				<div className="summary-of-benefits-header">Summary of Benefits for Dental Plan</div>
				<div className="summary-of-benefits-body">
					<div className="body-container-top">
						<div className="column-container">
							<div className="first-column section-heading">
								<div className="heading">Calendar Year Maximum</div>
							</div>
						</div>

						<div className="column-container">
							<div className="first-column">
								<div>Applicable to plan members as of age 18 years</div>
							</div>
							<div className="third-column">$1,500</div>
						</div>

						<div className="column-container">
							<div className="first-column section-heading">
								<div className="heading">Calendar Year Deductible</div>
							</div>
						</div>

						<div className="column-container">
							<div className="first-column">
								<div>Individual</div>
							</div>
							<div className="third-column">$50</div>
						</div>

						<div className="column-container">
							<div className="first-column">
								<div>Family</div>
							</div>
							<div className="third-column">$150</div>
						</div>
					</div>

					<div className="body-container-bottom">
						<div className="column-container">
							<div className="first-column section-heading">
								<div className="heading">Benefit Percentage Payable</div>
							</div>
						</div>

						<div className="column-container">
							<div className="first-column">
								<div>Preventive Care Services</div>
							</div>
							<div className="third-column">100%</div>
						</div>

						<div className="column-container">
							<div className="first-column">
								<div>Basic Services</div>
							</div>
							<div className="third-column">80% after deductible</div>
						</div>

						<div className="column-container">
							<div className="first-column">
								<div>Major Services</div>
							</div>
							<div className="third-column">50% after deductible</div>
						</div>

						<div className="column-container">
							<div className="first-column">
								<div>Orthodontic Services (Child Only)</div>
							</div>
							<div className="third-column">50% after deductible</div>
						</div>

						<div className="column-container">
							<div className="first-column">
								<div>Orthodontic Lifetime Maximum</div>
							</div>
							<div className="third-column">$1,500 per child</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default BopDentalPlanSummary;
