import React, { useState } from "react";
import "./BreakingNewsAlert.css";
import { cleanString } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";
import NoticeBoard from "../noticeBoard/NoticeBoard";
/* eslint-disable react/jsx-no-comment-textnodes */
const BreakingNewsAlert = () => {
	const [activeNotice, setActiveNotice] = useState<boolean>(false);
	const handleClick = () => {
		setActiveNotice(true);
		console.log("Notice clicked");
	};
	const handleCloseNotice = () => {
		setActiveNotice(false); // Close the NoticeBoard when it's closed
	};
	return (
		<>
			<div id="breaking-news-container">
				<div id="breaking-news-colour" className="slideup animated"></div>

				<span className="breaking-news-title delay-animated slidein">// NOTICE //</span>

				<a className="breaking-news-headline delay-animated2 fadein marquee">
					NOTICE TO ALL EMPLOYEES ELIGIBLE FOR THE GROUP HEALTH PLAN REGARDING IRS FORM 1095-C
					<button onClick={handleClick}>Read More..</button>
				</a>
			</div>
			{activeNotice && <NoticeBoard closeNotice={handleCloseNotice} />}
		</>
	);
};

export default BreakingNewsAlert;
