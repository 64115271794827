import React, { useEffect, useState } from "react";
import "./summaryOfBenefits.css";
import BopHealthPlanSummary from "./BOP_Health_Plan/BopHealthPlan";
import BopDentalPlanSummary from "./BOP_Dental_Plan/BopDentalPlan";
import BopVisionPlanSummary from "./BOP_Vision_Plan/BopVisionPlan";
import { EligibilityResponse } from "../../../../@types/eligibilityCheck.types";

const SummaryOfBenefitsBOP = ({ eligibilityReponse }: { eligibilityReponse: EligibilityResponse }) => {
	const [componentsToRender, setComponentsToRender] = useState<JSX.Element[]>([]);

	useEffect(() => {
		if (eligibilityReponse?.result?.[0]) {
			const { ELIGFLAG01, ELIGFLAG02, ELIGFLAG03, OVRPLN, OVRPPOPLN } = eligibilityReponse.result[0];
			const components: JSX.Element[] = [];

			const subPlan = Number(OVRPLN) === 232 && Number(OVRPPOPLN) === 233 ? "copay" : "highDeductible";

			// Add components based on flags
			if (ELIGFLAG01 === "T") {
				components.push(<BopHealthPlanSummary key="healthPlan" subPlan={subPlan} />);
			}
			if (ELIGFLAG02 === "T") {
				components.push(<BopDentalPlanSummary key="dentalPlan" />);
			}
			if (ELIGFLAG03 === "T") {
				components.push(<BopVisionPlanSummary key="visionPlan" />);
			}

			setComponentsToRender(components);
		}
	}, [eligibilityReponse]);

	return (
		<>
			<div className="summary-of-benefits-main-container">
				{componentsToRender.length > 0 ? componentsToRender : <div>No plan selected</div>}
			</div>

			<div className="summary-of-benefits-body-mobile">
				{componentsToRender.length > 0 ? componentsToRender : <div>No plan selected</div>}
			</div>
		</>
	);
};

export default SummaryOfBenefitsBOP;
