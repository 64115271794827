import React from "react";
import "./summaryofvisionprintStyles.css";

const BopVisionPlanSummaryPrint = () => {
	return (
		<div className="visionsummaryprint" style={{ marginTop: "200px" }}>
			<div className="summary-of-benefits-header">Summary of Benefits for Vision Plan</div>
			<div className="summary-of-benefits-body">
				{/* Section for Examination */}
				<div className="body-container-top">
					<div className="column-container section-heading">
						<div className="heading">Examination</div>
						<div className="first-column" style={{ marginLeft: 200 }}>
							$10 Copay
						</div>
					</div>
					<div className="column-container">
						<div className="first-column">If glasses or contacts are required</div>
						<div className="third-column">Additional $25 copay</div>
					</div>
					<div className="column-container">
						<div className="first-column">Limit annually</div>
						<div className="third-column">1 per year up to $50</div>
					</div>
					<div className="column-container">
						<div className="first-column">Frames</div>
						<div className="third-column">100% up to $70</div>
					</div>
					<div className="column-container">
						<div className="first-column">Frame limit annually</div>
						<div className="third-column">1 per year</div>
					</div>
					<div className="column-container">
						<div className="first-column">Lenses</div>
						<div className="third-column">100% up to limited benefit </div>
					</div>
					<div className="column-container">
						<div className="first-column">Lense limit annually</div>
						<div className="third-column">1 per year</div>
					</div>
				</div>

				{/* Section for Frames */}
				{/* <div className="body-container-middle">
					<div className="column-container section-heading">
						<div className="heading"></div>
					</div>
					<div className="column-container">
						<div className="first-column">Coverage</div>
						<div className="third-column">100% up to $70</div>
					</div>
					<div className="column-container">
						<div className="first-column">Frame limit annually</div>
						<div className="third-column">1 per year</div>
					</div>
				</div> */}

				{/* Section for Contact Lenses */}
				<div className="body-container-bottom">
					<div className="column-container section-heading">
						<div className="heading">Contact Lenses</div>
					</div>
					<div className="column-container">
						<div className="first-column" style={{ fontSize: "15px", fontWeight: "bold", color: "black" }}>
							Necessary lenses
						</div>
						<div className="third-column">100% up to $210</div>
					</div>
					<div className="column-container">
						<div className="first-column">Contact lense limit annually</div>
						<div className="third-column">1 per year</div>
					</div>
					<div className="column-container">
						<div className="first-column">Lense fitting and evaluation</div>
						<div className="third-column">100%</div>
					</div>
					<div className="column-container">
						<div className="first-column">Limit annually</div>
						<div className="third-column">1 per year</div>
					</div>

					<div className="column-container" style={{ marginTop: "25px" }}>
						<div className="first-column" style={{ fontSize: "15px", fontWeight: "bold", color: "black" }}>
							Elective lenses
						</div>
						<div className="third-column">100% up to $105</div>
					</div>
					<div className="column-container">
						<div className="first-column">Contact lense limit annually</div>
						<div className="third-column">1 per year</div>
					</div>
					<div className="column-container">
						<div className="first-column">Lense fitting and evaluation</div>
						<div className="third-column">$60 copay, then 100%</div>
					</div>
					<div className="column-container">
						<div className="first-column">Limit annually</div>
						<div className="third-column">1 per year</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BopVisionPlanSummaryPrint;
