export const NEXCALIBER_ADDRESS = "14800 Quorum Dr # 540, Dallas TX 75254 United States";
export const NEXCALIBER_CONTACT = "+1 972-248-1011";
export const NEXCALIBER_MAIL = "info@nexcaliber.com";
export const NEXCALIBER_MAIL_TWO = "qpa-idr@nexcaliber.com";

export const NEXCALIBER_DETAILS = {
	NEXCALIBER_ADDRESS,
	NEXCALIBER_CONTACT,
	NEXCALIBER_MAIL,
	NEXCALIBER_MAIL_TWO
};
