/* eslint-disable arrow-parens */
import { RefAttributes, forwardRef } from "react";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Alert } from "@mui/material";

// import { HiOutlineSearch } from "react-icons/hi";

// import axios from "axios";
// import { HiOutlineEmojiSad, HiOutlineEmojiHappy } from "react-icons/hi";

import dayjs, { Dayjs } from "dayjs";
// import EligibilityCheckDetailCard from "./eligibilityCheckDetailCardPrint/EligibilityCheckDetailCardPrint";
// import InputMask from "react-input-mask";

import "./eligibilityCheckFormPrint.css";
// import {
// 	EligibilityCheckCoveredEmployee,
// 	EligibilityCheckEligibleMembers
// } from "../../../@types/eligibilityCheck.types";

// import {
// 	dateTimeZoneMaterialUIConverter,
// 	mongoDBDateParserToUSAFormat_v2,
// 	monthDayFormatterForWLTQuery,
// 	monthDayFormatterForWLTQueryForAutoSearch
// } from "../../../utils/commonFunctions/dates";
// import { roleMapper } from "../../../utils/commonFunctions/roleMapper";
// import { relationshipMapper } from "../../../utils/commonFunctions/relationshipMapper";
import { Blocks, Hourglass, ThreeCircles } from "react-loader-spinner";
import SummaryOfBenefits from "./summaryOfBenefitsFormPrint/SummaryOfBenefitsFormPrint";

import { FaEye, FaEyeSlash, FaFileAlt } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";
import { useReactToPrint } from "react-to-print";

import PropTypes from "prop-types";
import { BOP_Black_logo, BrookshireBrothersCircleLogo, NexcaliberPurpleLogo, PL_logo } from "../../../../assets";
import { NEXCALIBER_DETAILS } from "../../../../constants/nexcaliberDetails";
import {
	EligibilityCheckCoveredEmployee,
	EligibilityCheckEligibleMembers,
	EligibilityResponse
} from "../../../../@types/eligibilityCheck.types";
import EligibilityCheckDetailCardPrint from "./eligibilityCheckDetailCardPrint/EligibilityCheckDetailCardPrint";
import CoveredDependents from "../coveredDependents/CoveredDependents";
import SummaryOfBenefitsFormPrint from "./summaryOfBenefitsFormPrint/SummaryOfBenefitsFormPrint";
import CoveredDependentsPrint from "./coveredDependentsPrint/CoveredDependentsPrint";
import SummaryOfBenefitsICIFormPrint from "./summaryOfBenefitsICIFormPrint/SummaryOfBenefitsICIFormPrint";
import SummaryOfBenefitsBOPFormPrint from "./summaryOfBenefitsBOPFormPrint/SummaryOfBenefitsBOPFormPrint";
import SummaryOfProgressivePrint from "./summaryOfBenefitsPLFormPRint/SummaryOfProgressivePrint";

export interface EligibilityCheckFormPrintProp {
	eligibleMembers: EligibilityCheckEligibleMembers;
	coveredEmployee: EligibilityCheckCoveredEmployee | undefined | null;
	eligibilityReponse: EligibilityResponse;
}

const EligibilityCheckFormPrint: React.ForwardRefExoticComponent<
	EligibilityCheckFormPrintProp & RefAttributes<HTMLDivElement>
	/* eslint-disable react/prop-types */
> = forwardRef(({ eligibleMembers, coveredEmployee, eligibilityReponse }, ref): JSX.Element => {
	const showSecondCard = () => {
		console.log("===>benifits number", eligibleMembers?.group_number);
		switch (eligibleMembers?.group_number) {
			case 219:
				return <CoveredDependentsPrint />;
			case 220:
				return <SummaryOfBenefitsFormPrint />;
			case 188:
				return <SummaryOfBenefitsICIFormPrint />;
			case 225:
				return <SummaryOfBenefitsBOPFormPrint eligibilityReponse={eligibilityReponse} />;
			case 300:
				return <SummaryOfProgressivePrint />;
		}
	};

	const renderGroupLogo = () => {
		switch (eligibleMembers?.group_number) {
			case 220:
				return (
					<img src={BrookshireBrothersCircleLogo} className="group-logo-bbi" alt="Brookshire Brothers Logo" />
				);
			case 225:
				return <img src={BOP_Black_logo} className="group-logo-bop" alt="BOP Black Logo" />;
			case 300:
				return <img src={PL_logo} className="group-logo-pl" alt="PL Logo" />;
			default:
				return null;
		}
	};
	return (
		<div className="printable-ligibility-check" ref={ref}>
			<div className="eligibility-check-paper-container">
				{/* ============================= HEADER CONTAINER ================================= */}
				<div className="header-container">
					<div className="group-logo-container">{renderGroupLogo()}</div>
					<div className="nexcaliber-logo-container">
						<img src={NexcaliberPurpleLogo} className="nexcaliber-logo" alt="" />
						<div className="nexcaliber-location-details">
							<span>Nexcaliber</span>
							<span>PO BOX 802422, Dallas Texas 75380</span>
							<span>{NEXCALIBER_DETAILS.NEXCALIBER_CONTACT}</span>
						</div>
					</div>
				</div>
				{/* ============================= BODY CONTAINER ====================================*/}
				<div className="theme-header-1 header">Eligibility and Benefit Validation</div>

				<Alert severity="warning" sx={{ fontSize: "1rem" }} className="disclaimer-container">
					<strong className="disclaimer">Disclaimer:</strong> The information provided here is for general
					informational purposes only. It does not constitute professional advice. Any reliance you place on
					such information is strictly at your own risk. Always seek the advice of a qualified professional
					for any concerns you may have. We do not endorse or guarantee the accuracy, completeness, or
					suitability of the information provided.
				</Alert>

				<EligibilityCheckDetailCardPrint cardDetailProp={eligibleMembers} coveredEmployee={coveredEmployee} />
				<div
					style={
						eligibleMembers?.group_number === 225 || eligibleMembers?.group_number === 188
							? { marginTop: "700px" }
							: { marginTop: "0px" }
					}
				>
					{showSecondCard()}
				</div>
			</div>
		</div>
	);
});

EligibilityCheckFormPrint.displayName = "EligibilityCheckPrint";
EligibilityCheckFormPrint.propTypes = {
	eligibleMembers: PropTypes.any.isRequired,
	coveredEmployee: PropTypes.any.isRequired
};

export default EligibilityCheckFormPrint;
